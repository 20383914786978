import React, { Component } from "react"
import { connect } from "react-redux"
import {
  startAddSalesLevelToProject,
  startApproveProject,
  startEditProject,
  startGetProject,
  startRemoveConsultantFromProject,
  startToggleTimeReporting,
  startGetSalesLevel,
  startSalesLevelToggleTimeReporting,
  startGetGDPRForm,
  startSubmitGDPRForm,
  startGetGDPRAnswers,
  startUpdateGDPRAnswers,
  startGetRiskEvalAssets,
  startGetRiskEvalAssetsDetails,
  startGetRiskEvalSurvay,
  startSubmitRiskEvalForm,
  startSubmitRiskEvalSurvey,
} from "../../../store/actions/core/project/project"
import { startGetSalesLevels } from "../../../store/actions/shared/sales_level"
import { Button, Checkbox, Container, Dimmer, Icon, Loader, Segment } from "semantic-ui-react"
import ProjectManagerViewComponent from "../../../components/core/project/ProjectManagerViewComponent"
import Can from "../../../abilities/Can"
import { AddSalesLevelModal } from "../../../components/core/project/AddSalesLevelModal"
import GDPRForm from "../../../components/core/project/GDPRForm"
import RiskAssessmentForm from "../../../components/core/project/RiskAssessmentForm"
import { history } from "../../../routers/AppRouters"
import ProjectForm from "../../../components/core/project/ProjectForm"
import projectFormData from "../../../assets/resources/project"
import projectFormDataGdprRisk from "../../../assets/resources/project_gdpr_risk"
import projectEditFormData from "../../../assets/resources/projectEdit"
import IconButton from "../../../components/shared/buttons/IconButton"
import { startSearchConsultant, resetSearch } from "../../../store/actions/core/administration/manage_consultant"
import _ from "lodash"
import { startGetCurrencies } from "../../../store/actions/shared/currency"
import { startGetCompanies } from "../../../store/actions/shared/company"
import { startGetProject_manager } from "../../../store/actions/core/project/project_manager"
import { startGetProjectTypes } from "../../../store/actions/core/administration/project_type"
import { startGetCustomers } from "../../../store/actions/core/customer-creation/customer"
// AEP-137
import { startGetExpensesApprover } from "../../../store/actions/core/offer-request/expenses-approver/expenses_approver"
import { startGetUseApprovers } from "../../../store/actions/core/offer-request/expenses-approver/use_approvers"
// AEP-137
import { toast } from "react-semantic-toasts"
import constants from "../../../utils/constants"
import { startGetOfferingTypes } from "../../../store/actions/core/administration/offering_type";
import offering_type from "../../../assets/resources/offering_type"

class ProjectDetailsContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      consultantSearch: "",
      editable: false,
      required: [],
      selectedConsultant: {},
      addSalesLevelModalOpen: false,
      GDPRFormOpen: false,
      selectedSalesLevel: {},
      salesLevels: [],
      view_daily_rate: true,
      errorNoMoreBudget: false,
      editRiskEvaluationForm: false,
      alreadySetted: false,
      show_use_expenses_approver: false, // AEP-137
    }
  }

  componentWillMount() {
    this.props.GetGDPRAnswers(this.props.match.params.project_id)
    this.props.getProjectDetails(this.props.match.params.project_id)
    this.props.getSalesLevel(this.props.match.params.project_id)
    this.props.getSalesLevels()
    this.props.getGDPRForm(this.props.match.params.project_id)
    this.props.getRiskEvalAsset(this.props.match.params.project_id)
    this.props.getUseApprovers()
    this.props.getOfferingTypes();
  }

  componentDidMount() {
    this.checkPropsAndUpdateState()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.use_approver !== this.props.use_approver || prevProps.userCompany_id !== this.props.userCompany_id) {
      this.checkPropsAndUpdateState()
    }
  }

  checkPropsAndUpdateState = () => {
    const shouldShow = this.props.use_approver === 1 && this.props.userCompany_id === this.props.authCompany_id
    this.setState({ show_use_expenses_approver: shouldShow })
  }

  componentWillReceiveProps(props) {
    let saleLevels = []
    if (props.project.type_code !== "TS") {
      saleLevels = props.saleLevels
      saleLevels = _.toArray(
        _.mapValues(saleLevels, (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.code,
          }
        })
      )

      saleLevels = _.differenceBy(props.salesLevelsOther, saleLevels, "value")
    } else {
      saleLevels = props.saleLevels
      saleLevels = _.toArray(
        _.mapValues(saleLevels, (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.code,
          }
        })
      )
      saleLevels = _.differenceBy(props.salesLevels, saleLevels, "value")
    }
    this.setState({
      salesLevels: saleLevels,
    })

    this.controlForTimereportingBudget(props)

    if (props.project !== undefined && props.project !== this.props.project) {
      if (props.project.risk_flag === 1) {
        props.project.risk_flag = "Completed"
      } else {
        props.project.risk_flag = "Not Completed"
      }
    }

    if (
      props.gdpr_answers !== undefined &&
      props.gdpr_answers !== this.props.gdpr_answers &&
      !this.state.alreadySetted
    ) {
      if (props.project.gdpr_flag === 1) {
        if (props.gdpr_answers.length > 0) {
          props.project.gdpr_flag = "Completed"
          this.setState({ alreadySetted: true })
        } else {
          props.project.gdpr_flag = "Not Requested"
          this.setState({ alreadySetted: true })
        }
      } else {
        props.project.gdpr_flag = "Not Completed"
      }
    }

  }

  controlForTimereportingBudget = (props) => {
    const today = new Date().setUTCHours(0, 0, 0, 0)
    if (props.project.budget_end_date != null || props.project.budget_start_date != null) {
      let project_end_date = new Date(props.project.budget_end_date).setUTCHours(0, 0, 0, 0)
      let project_start_date = new Date(props.project.budget_start_date).setUTCHours(0, 0, 0, 0)
      if (project_end_date < today || project_start_date > today) {
        this.setState({
          errorNoMoreBudget: true,
        })
      } else {
        this.setState({
          errorNoMoreBudget: false,
        })
      }
    } else {
      this.setState({ errorNoMoreBudget: false })
    }
  }

  resetComponent = () => {
    this.props.resetSearch()
    this.setState({
      consultantSearch: "",
    })
  }

  addSalesLevels = () => {
    this.setState({
      addSalesLevelModalOpen: true,
    })
  }

  openGDPRForm = () => {
    if (this.props.project.gdpr_flag === "Completed" || this.props.project.gdpr_flag === "Not Requested") {
      this.props.project.gdpr_flag = 1
    } else {
      this.props.project.gdpr_flag = 0
    }
    this.setState({
      GDPRFormOpen: true,
    })
  }

  openRiskForm = () => {
    if (this.props.project.risk_flag === "Completed") {
      this.props.project.risk_flag = 1
    } else {
      this.props.project.risk_flag = 0
    }
    this.setState({ editRiskEvaluationForm: true })
  }

  addSalesLevelToProject = () => {
    let description = "";
    const onFailure = res => {
      if (res.response.status === 412)
        description = "You are not authorized to take this action.";
      else if (res.response.status === 409)
        description = "Sales Levels is already present in this project.";
      else if (res.response.status === 406)
        description = res.response.data.error;
      else
        description = "Error adding salels level to project.";

      toast(
        {
          title: "Error",
          description: description,
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT
        });
    };

    const onSuccess = (res) => {
      toast({
        title: "Success",
        description: "Sales level correctly added to project.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
      })
    }

    const params = this.state.selectedSalesLevel
    params.rate = Number(params.rate)

    if ("block_hours" in params) {
      params.block_hours = params.block_hours === 0 ? null : Number(params.block_hours)
    }

    const projectId = this.props.match.params.project_id

    if (parseInt(params.rate, 10) >= 0) {
      delete this.state.selectedSalesLevel.name
      this.props.addSalesLevelToProject(projectId, params, onSuccess, onFailure)
      this.handleClose()
    } else {
      toast({
        title: "Error",
        description: "The sales level rate must be >= 0",
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
      })
    }
  }

  handleToggleTimeReporting = (e, data) => {

    const projectId = this.props.project.id;
    const status = data.checked;
    this.props.toggleTimeReporting(projectId, status);
  };

  handleTableToggleChange = (e, data) => {
    e.preventDefault()
    e.stopPropagation()

    const onFailure = res => {
      let description = "";
      if (res.response && res.response.status === 412)
        description = "You are not authorized to perform this action.";
      else if (res.response && res.response.status === 406)
        description = res.response.data.error;
      else if (res.response)
        description = "Error changing timereporting status.";
      else
        description = "You are not authorized to perform this action.";
      toast(
        {
          title: "Sales level Time Reporting",
          description: description,
          type: "error",
          icon: "calendar times",
          time: constants.TOAST_ERROR_TIMEOUT
        });
    };

    const onSuccess = res => {
      toast(
        {
          title: "Sales level Time Reporting",
          description: "Timereporting Sales Level change correctly.",
          icon: "clock",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT
        }
      );
    }

    const status = data.checked;
    this.props.salesLevelToggleTimeReporting(this.props.match.params.project_id, data.id, status, onSuccess, onFailure);
  }

  handleSearchChange = (e, data) => {
    this.setState({
      consultantSearch: data.searchQuery,
    })

    if (this.state.consultantSearch.length >= 2) {
      this.props.searchConsultants({ query: data.searchQuery })
    } else {
      this.props.resetSearch();
    }
  }

  handleChangeConsultant = (e, data) => {
    this.setState((prevState) => ({
      selectedConsultant: {
        ...prevState.selectedConsultant,
        unique_id: data.value,
      },
    }))
  }

  handleChangeSaleLevel = (e, data) => {
    let textValue = ""
    if (data.options) {
      textValue = data.options.find((o) => o.value === data.value).text
    }
    if (this.props.project.type_code !== "TS") {
      this.setState((prevState) => ({
        selectedSalesLevel: {
          ...prevState.selectedSalesLevel,
          [data.name]: textValue,
          level: data.value,
          rate: 1,
        },
      }))
    } else {
      this.setState((prevState) => ({
        selectedSalesLevel: {
          ...prevState.selectedSalesLevel,
          [data.name]: textValue,
          level: data.value,
        },
      }))
    }
  };

  handleChangeRate = (e, data) => {
    let rate = 0;
    if (data.name === "rate" && this.props.project.type_code !== "TS") {
      rate = 1
      data.value = 1
    } else {
      rate = data.value
    }
    this.setState(prevState => (
      {
        selectedSalesLevel: {
          ...prevState.selectedSalesLevel,
          [data.name]: rate
        }
      }
    ));
  };

  handleRoleChange = (e, data) => {
    this.setState(prevState => (
      {
        selectedSalesLevel: {
          ...prevState.selectedSalesLevel,
          [data.name]: data.value
        }
      }
    ));
  };

  handleBlockHoursChange = (e, data) => {
    let rate = 0;
    if (data.name === "block_hours" && this.props.project.type_code !== "TS") {
      rate = 0
      data.value = 0
    } else {
      rate = data.value
    }

    data.value = data.value === 0 ? null : data.value
    this.setState((prevState) => ({
      selectedSalesLevel: {
        ...prevState.selectedSalesLevel,
        [data.name]: rate,
      },
    }))

  };

  editProject = () => {
    const { options, project } = this.props;
    if (options.currency.length === 0) this.props.getCurrencies();
    if (options.company.length === 0) this.props.getCompanies();
    if (options.manager.length === 0) this.props.getManagers();
    if (options.type.length === 0) this.props.getProjectTypes();
    if (options.approver.length === 0) this.props.getExpensesApprover(); // AEP-137
    if (options.billing_customer.length === 0 || options.final_customer.length === 0) this.props.getCustomers();
    if (options.offering_type_id.length === 0) this.props.getOfferingTypes();

    // console.log('props: ', this.props);
    // console.log('project.offering_type: ', project.offering_type);
    

    this.setState({
      
      editable: true,
      values: {
        id: project.id,
        type: project.type_id,
        name: project.name,
        company: project.company_id,
        company_name: project.company_name,
        manager: project.manager_id,
        currency: project.currency_id,
        billing_customer: project.billing_customer_id,
        final_customer: project.final_customer_id,
        approver: project.approver_unique_id ? project.approver_unique_id : project.manager_id,   // AEP-137
        offering_type_id: project.offering_type_id
      }
    });
  };

  onApprove = () => {
    const projectId = this.props.match.params.project_id

    let payload

    if (this.state.editable) {
      // form is in edit, so send also  updated parameters
      payload = JSON.parse(JSON.stringify(this.state.values))
    }

    this.props.approveProject(projectId, payload)
    this.setState({
      editable: false,
      values: undefined,
    })
  }

  handleEditSubmit = () => {
    // console.log("[handleEditSubmit]", this.state.values);
    this.setState((prev) => {
      const updatedValues = { ...prev.values };
      if (!prev.show_use_expenses_approver) {
        updatedValues.approver = prev.values.manager;
      }
      delete updatedValues['company_name'];

      this.props.editProject(JSON.parse(JSON.stringify(updatedValues)));

      return {
        editable: false,
        values: undefined
      };
    });

  };

  handleChange = (e, { name, value }) => {
    this.setState(prevState => ({
      values: {
        ...prevState.values,
        [name]: value,
      },
    }))
  }

  handleBlockingChange = (e, data) => {
    this.setState(prevState => (
      {
        selectedSalesLevel: {
          ...prevState.selectedSalesLevel,
          [data.name]: data.checked
        }
      }
    ));
  }

  handleRowClick = clickedRow => {
    history.push("/project/" + this.props.project.id + "/sales_level/" + clickedRow.id);
  };

  handleClose = () =>
    this.setState({
      addSalesLevelModalOpen: false,
      selectedConsultant: {},
    })

  handleGDPRClose = () => {
    if (this.props.gdpr_answers !== undefined) {
      if (this.props.project.gdpr_flag === 1) {
        if (this.props.gdpr_answers.length > 0) {
          this.props.project.gdpr_flag = "Completed"
          this.setState({ alreadySetted: false })
        } else {
          this.props.project.gdpr_flag = "Not Requested"
          this.setState({ alreadySetted: false })
        }
      } else {
        this.props.project.gdpr_flag = "Not Completed"
      }
    }
    this.setState({
      GDPRFormOpen: false,
    })
  }

  handleCloseRisk = () => {
    if (this.props.project.risk_flag === 1) {
      this.props.project.risk_flag = "Completed"
    } else {
      this.props.project.risk_flag = "Not Completed"
    }
    this.setState({ editRiskEvaluationForm: false })
  }

  submitGDPR = (answers) => {
    if (this.props.project.gdpr_flag) this.props.updateGDPRAnswers(this.props.match.params.project_id, answers)
    else this.props.submitGDPR(this.props.match.params.project_id, answers)
  }

  onCancel = () => {
    if (this.props.project.risk_flag === 1) {
      this.props.project.risk_flag = "Completed"
    } else {
      this.props.project.risk_flag = "Not Completed"
    }
    this.setState({ editRiskEvaluationForm: false })
    this.props.getRiskEvalAsset(this.props.match.params.project_id)
  }

  submitRisk = () => {
    if (this.props.project.risk_flag === 1) {
      this.props.project.risk_flag = "Completed"
    } else {
      this.props.project.risk_flag = "Not Completed"
    }
    this.state.alreadySetted = false
    this.setState({ alreadySetted: false })
    this.setState({ editRiskEvaluationForm: false })
    this.props.GetGDPRAnswers(this.props.match.params.project_id)
    this.props.getRiskEvalAsset(this.props.match.params.project_id)
  }

  render() {
    const { editable } = this.state
    const { project } = this.props
    // console.log(projectFormData)

    return (
      <Container>
        <AddSalesLevelModal
          open={this.state.addSalesLevelModalOpen}
          handleClose={this.handleClose}
          salesLevel={this.state.salesLevels}
          loading={this.props.loading_consultants}
          handleChangeSaleLevel={this.handleChangeSaleLevel}
          handleChange={this.handleChangeRate}
          handleRoleChange={this.handleRoleChange}
          handleBlockHoursChange={this.handleBlockHoursChange}
          handleBlockingChange={this.handleBlockingChange}
          selectedSalesLevel={this.state.selectedSalesLevel}
          addSalesLevelToProject={this.addSalesLevelToProject}
          project={this.props.project}
          view_rate={project.type_code === "TS"}
        />

        <Dimmer active={Boolean(this.props.loading)} inverted>
          <Loader content="Loading" />
        </Dimmer>

        {/* TODO fix with correct action*/}
        <Can I="projects:Read" a="projects">
          {() => (
            <Segment className="project_details" loading={this.props.loading} clearing>
              {!editable && !this.state.editRiskEvaluationForm && !this.state.GDPRFormOpen && (
                <React.Fragment>
                  <Can I="projects:Update" a="projects">
                    {() => <IconButton label="Edit" floated="right" icon="edit" onClick={this.editProject} />}
                  </Can>

                  {project.type_code !== "INV" &&
                    project.status_code !== "pending" &&
                    this.state.salesLevels.length > 0 && (
                      <Can I="projects:Update" a="projects">
                        {() => (
                          <IconButton
                            label="Add Sales Levels"
                            floated="right"
                            icon="add"
                            onClick={this.addSalesLevels}
                          />
                        )}
                      </Can>
                    )}
                  {project.status_code !== "pending" && project.has_risk_assessment === 1 && (
                    <Can I="projects:Update" a="risk-assessment">
                      {() => (
                        <IconButton
                          label="Additional project information for risk evalution"
                          floated="right"
                          icon="edit"
                          onClick={this.openRiskForm}
                        />
                      )}
                    </Can>
                  )}
                  {this.props.gdpr_form &&
                    project.status_code !== "pending" &&
                    this.props.gdpr_answers &&
                    project.has_gdpr == 1 && (
                      <Can I="projects:Update" a="gdpr">
                        {() => <IconButton label="GDPR form" floated="right" icon="edit" onClick={this.openGDPRForm} />}
                      </Can>
                    )}

                  <Button icon className="cancel" floated="left" onClick={() => history.goBack()}>
                    <Icon name="arrow left" />
                  </Button>

                  {project.status_code === "pending" && ( // project is Pending
                    <Can I="projects:Update" a="projects-approval">
                      <IconButton onClick={this.onApprove} label="Approve" floated="right" icon="thumbs up" />
                    </Can>
                  )}
                </React.Fragment>
              )}

              {this.state.editRiskEvaluationForm || this.state.GDPRFormOpen ? (
                <>
                  {this.state.GDPRFormOpen ? (
                    <Can I="projects:Update" a="gdpr">
                      <GDPRForm
                        handleClose={this.handleGDPRClose}
                        gdpr_form={this.props.gdpr_form}
                        gdpr_compiled={this.props.project.gdpr_flag}
                        previous_answers={this.props.gdpr_answers}
                        submitGDPR={this.submitGDPR}
                        loading={this.props.loading_GDPR_form}
                        final_customer={this.props.project.final_customer_name}
                      />
                    </Can>
                  ) : (
                    <Can I="projects:Update" a="risk-assessment">
                      <RiskAssessmentForm
                        onCancel={this.onCancel}
                        goBack={this.handleCloseRisk}
                        onSubmit={this.submitRisk}
                        optionsAsset={this.props.risk_eval_assets}
                        getAssetsDetails={(assets_code) =>
                          this.props.getRiskEvalAssetsDetails(this.props.match.params.project_id, assets_code)
                        }
                        assetsDetails={this.props.risk_eval_assets_details}
                        getSurvey={(main_asset_code) =>
                          this.props.getRiskEvalSurvey(this.props.match.params.project_id, main_asset_code)
                        }
                        survey={this.props.risk_eval_survey}
                        submitRiskEvalSurvey={(answers) =>
                          this.props.submitRiskEvalSurvey(this.props.match.params.project_id, answers)
                        }
                        submitRiskEvalForm={(vulnerabilities_codes) =>
                          this.props.submitRiskEvalForm(this.props.match.params.project_id, vulnerabilities_codes)
                        }
                        loading={this.props.loading_risk_eval_form}
                      />
                    </Can>
                  )}
                </>
              ) : (
                <>
                  <ProjectForm
                    formFields={
                      editable
                        ? projectEditFormData
                        : project.has_risk_assessment === 1 && project.has_gdpr === 1
                        ? projectFormDataGdprRisk
                        : projectFormData
                    }
                    values={editable ? this.state.values : this.props.project}
                    loadings={this.props.loadings}
                    options={this.props.options}
                    editable={editable}
                    handleChange={this.handleChange}
                    // onSubmit={() => console.log("SUBMIT")}
                    errorNoMoreBudget={this.state.errorNoMoreBudget}
                    showUseExpensesApprover={this.state.show_use_expenses_approver}
                  />
                  {editable && (
                    <div style={{ marginTop: "20px" }}>
                      <Button className="cancel" floated="left" onClick={() => this.setState({ editable: false })}>
                        Cancel
                      </Button>
                      <IconButton
                        floated="right"
                        icon="save"
                        label="Submit"
                        disabled={false} // TODO should buttons go inside Reusable form?
                        onClick={this.handleEditSubmit}
                      />
                    </div>
                  )}

                  {!editable && project.status_code !== "pending" && (
                    <div>
                      <Can I="projects:Update" a="my-projects">
                        <Checkbox
                          toggle
                          key={0}
                          checked={this.props.project.tr_enabled === 1}
                          label="Time Reporting"
                          onChange={this.handleToggleTimeReporting}
                        />
                      </Can>

                      <Can I="projects:Read" a="projects">
                        {this.props.saleLevels !== "SA" && !this.state.GDPRFormOpen && (
                          <ProjectManagerViewComponent
                            key="2"
                            onChangeToggle={this.handleTableToggleChange}
                            project={project}
                            saleLevels={this.props.saleLevels}
                            loading={this.props.loading_salesLevel}
                            handleRemoveSaleLevel={this.handleRemoveSaleLevelModalOpen}
                            handleRowClick={this.handleRowClick}
                            sales_consultant={false}
                          />
                        )}
                      </Can>
                    </div>
                  )}
                </>
              )}
            </Segment>
          )}
        </Can>
      </Container>
    )
  }
}

function mapStateToProps(state) {
  return {
    loading: state.projectReducer.loading,
    project: state.projectReducer.project,
    saleLevels: state.projectReducer.sale_levels,
    user_groups: state.authReducer.user_groups,
    // AEP-137
    authCompany_id:
      state.authReducer.user_details &&
      state.authReducer.user_details[0] &&
      state.authReducer.user_details[0].company_id,
    userCompany_id: state.useApproversReduer.company_id,
    // il progetto utilizza la funzionalità approver?
    use_approver: state.projectReducer.project.use_approver,
    // AEP-137

    consultant_options: _.toArray(
      _.mapValues(state.manageConsultantsReducer.consultants, (o) => {
        return {
          key: o.unique_id,
          value: o.unique_id,
          text: o.name + " " + o.surname + " (" + o.email + ")",
        }
      })
    ),

    salesLevels: _.toArray(
      _.mapValues(
        _.filter(state.salesLevelsReducer.levels, { sl_enabled: 1, visible: 1, publicly_available: 1 }),
        (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.code,
          }
        }
      )
    ),

    salesLevelsOther: _.toArray(
      _.mapValues(
        _.filter(state.salesLevelsReducer.levels, { sl_enabled: 1, visible: 1, publicly_available: 0 }),
        (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.code,
          }
        }
      )
    ),

    loading_salesLevel: state.salesLevelsReducer.loading,
    loading_consultants: state.manageConsultantsReducer.loading,
    loading_timereporting: state.projectReducer.loading_timereporting,

    options: {
      manager: _.toArray(
        _.mapValues(state.project_managerReducer.project_manager, (o) => {
          return {
            key: o.unique_id,
            value: o.unique_id,
            text: o.surname + " " + o.name + " - CN " + o.cn_id,
          }
        })
      ),

      // AEP-137
      approver: state.expensesApproverReducer.hours_expenses_approver
        ? state.expensesApproverReducer.hours_expenses_approver.map((o) => ({
            key: o.unique_id,
            value: o.unique_id,
            text: o.surname + " " + o.name + " - CN " + o.cn_id,
          }))
        : [],
      // AEP-137

      type: _.toArray(
        _.mapValues(state.projectTypeReducer.projecttypes, (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.name,
          }
        })
      ),

      company: _.toArray(
        _.mapValues(state.companyReducer.companies, (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.name,
          }
        })
      ),

      billing_customer: _.toArray(
        _.mapValues(state.customerReducer.customers, (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.name,
          }
        })
      ),

      final_customer: _.toArray(
        _.mapValues(state.customerReducer.customers, (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.name,
          }
        })
      ),

      currency: _.toArray(
        _.mapValues(state.currencyReducer.currencies, (o) => {
          return {
            key: o.id,
            value: o.id,
            text: o.code,
          }
        })
      ),
      offering_type_id: _.toArray(
        _.mapValues(
          _.filter(state.offeringTypeReducer.offeringtypes, o => {
            const project = state.projectReducer.project;

            if (!project) {
              return o.active === 1;
            }

            return o.activity_type_code === project.activity_type_code && o.active === 1;
          }),
          o => ({
            key: o.id,
            value: o.id,
            text: o.name
          })
        )
      )
    },

    loadings: {
      currency: state.currencyReducer.loading,
      company: state.companyReducer.loading,
      manager: state.project_managerReducer.loading,
      type: state.projectTypeReducer.loading,
      final_customer: state.customerReducer.loading,
      billing_customer: state.customerReducer.loading,
      approver: state.expensesApproverReducer.loading,
      offering_type_id: state.offeringTypeReducer.loading
    },

    gdpr_form: state.projectReducer.gdpr_form,
    gdpr_answers: state.projectReducer.gdpr_answers,

    loading_GDPR_form: {
      gdpr: state.projectReducer.loading_gdpr_form,
    },

    risk_eval_assets: state.projectReducer.risk_eval_assets,
    risk_eval_assets_details: state.projectReducer.risk_eval_assets_details,
    risk_eval_survey: state.projectReducer.risk_eval_survey,

    loading_risk_eval_form: {
      risk_eval: state.projectReducer.loading_risk_eval_form,
    },

    offeringTypes: state.offeringTypeReducer.offeringtypes
  }
}

function mapDispatchToProps(dispatch) {
  return {
    getProjectDetails: (projectId) => dispatch(startGetProject(projectId)),
    // getSaleLevelConsultants: (projectId) => dispatch(startGetSaleLevelConsultants(projectId)),
    searchConsultants: (param) => dispatch(startSearchConsultant(param, "READ_PROJECTS")),
    resetSearch: () => dispatch(resetSearch()),

    getSalesLevels: () => dispatch(startGetSalesLevels()),
    getSalesLevel: (project_id) => dispatch(startGetSalesLevel(project_id)),

    getGDPRForm: (project_id) => dispatch(startGetGDPRForm(project_id)),
    submitGDPR: (project_id, answers) => dispatch(startSubmitGDPRForm(project_id, answers)),
    updateGDPRAnswers: (project_id, answers) => dispatch(startUpdateGDPRAnswers(project_id, answers)),
    GetGDPRAnswers: (project_id) => dispatch(startGetGDPRAnswers(project_id)),

    getRiskEvalAsset: (project_id) => dispatch(startGetRiskEvalAssets(project_id)),
    getRiskEvalAssetsDetails: (project_id, assets_code) =>
      dispatch(startGetRiskEvalAssetsDetails(project_id, assets_code)),
    getRiskEvalSurvey: (project_id, main_asset_code) => dispatch(startGetRiskEvalSurvay(project_id, main_asset_code)),
    submitRiskEvalSurvey: (project_id, answers) => dispatch(startSubmitRiskEvalSurvey(project_id, answers)),
    submitRiskEvalForm: (project_id, vulnerabilities) => dispatch(startSubmitRiskEvalForm(project_id, vulnerabilities)),

    addSalesLevelToProject: (projectId, params, onSuccess, onFailure) =>
      dispatch(startAddSalesLevelToProject(projectId, params, onSuccess, onFailure)),
    removeConsultantFromProject: (projectId, consultantId) =>
      dispatch(startRemoveConsultantFromProject(projectId, consultantId)),

    approveProject: (projectId, params) => dispatch(startApproveProject(projectId, params)),
    editProject: (params) => dispatch(startEditProject(params)),
    toggleTimeReporting: (projectId, status) => dispatch(startToggleTimeReporting(projectId, status)),
    salesLevelToggleTimeReporting: (projectId, sale_level_id, status, onSuccess, onFailure) =>
      dispatch(startSalesLevelToggleTimeReporting(projectId, sale_level_id, status, onSuccess, onFailure, true)),

    getCurrencies: () => dispatch(startGetCurrencies()),
    getCompanies: () => dispatch(startGetCompanies()),
    getManagers: () => dispatch(startGetProject_manager("EDIT_PROJECTS")),
    getProjectTypes: () => dispatch(startGetProjectTypes("EDIT_PROJECTS")),
    getCustomers: () => dispatch(startGetCustomers("EDIT_PROJECTS")),

    // AEP-137
    getExpensesApprover: () => dispatch(startGetExpensesApprover("EDIT_PROJECTS")),
    getUseApprovers: () => dispatch(startGetUseApprovers()),

    getOfferingTypes: () => dispatch(startGetOfferingTypes()), 
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetailsContainer)
