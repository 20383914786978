import {
  APPROVE_EXPENSE_ERROR,
  APPROVE_EXPENSE_SUCCESS,
  APPROVE_REPORT_ERROR,
  APPROVE_REPORT_SUCCESS,
  EXPENSE_DETAILS_ERROR,
  EXPENSE_RECEIPT_ERROR,
  EXPENSE_RECEIPT_SUCCESS,
  EXPENSE_REPORTS_ERROR,
  GET_EXPENSE_DETAILS, STORE_EXPENSE_REPORT_FILTERS,
  GET_EXPENSE_REPORTS,
  GET_EXPENSES,
  LOADING_APPROVE_EXPENSE,
  LOADING_APPROVE_REPORT,
  LOADING_EXPENSE_DETAILS,
  LOADING_EXPENSE_RECEIPT,
  LOADING_EXPENSE_REPORTS,
  LOADING_EXPENSES,
  LOADING_REJECT_EXPENSE,
  REJECT_EXPENSE_SUCCESS,
  REJECT_EXPENSE_ERROR, LOADING_EXPENSE_REPORT_EXPORT, EXPENSE_REPORT_EXPORT_CLEAR,
  LOADING_VATS, GET_VATS, LOADING_EXPENSE_VAT, EXPENSE_VAT_ERROR, GET_EXPENSE_REPORT_FROM_ID,
  EXPENSE_PAYMENT_RECEIPT_SUCCESS
} from "../../../actions/core/administration/receipts";

const initialState = { pages: -1 };

export default (state = initialState, action) => {
  switch (action.type) {
    case LOADING_EXPENSE_REPORTS:
      return {
        ...state,
        loading: true
      };
    case LOADING_APPROVE_REPORT:
      return {
        ...state,
        loading_approve: true
      };
    case LOADING_EXPENSES:
      return {
        ...state,
        loading_expenses: true
      };
    case APPROVE_REPORT_SUCCESS:
      return {
        ...state,
        loading_approve: false
      };
    case APPROVE_REPORT_ERROR:
      return {
        ...state,
        loading_approve: false
      };
    case GET_EXPENSE_REPORTS:
      return {
        ...state,
        loading: false,
        reports: action.reports
      };
    case GET_EXPENSE_REPORT_FROM_ID:
      return{
        ...state,
        loading: false,
        selectedReport: action.report
      };
    case GET_EXPENSES:
      return {
        ...state,
        loading_expenses: false,
        loading_expense_vat:false,
        expenses: action.expenses
      };
    case EXPENSE_REPORTS_ERROR:
      return {
        ...state,
        loading: false,
        loading_expenses: false,
        error: action.error
      };
    case LOADING_EXPENSE_DETAILS:
      return {
        ...state,
        loading: true
      };
    case GET_EXPENSE_DETAILS:
      return {
        ...state,
        loading: false,
        expenseDetails: action.expenseDetails
      };
    case EXPENSE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      };
    case LOADING_APPROVE_EXPENSE:
      return {
        ...state,
        loadingApproval: true
      };
    case LOADING_REJECT_EXPENSE:
      return {
        ...state,
        loadingRejection: true
      };
    case APPROVE_EXPENSE_SUCCESS:
      return {
        ...state,
        loadingApproval: false
      };
    case APPROVE_EXPENSE_ERROR:
      return {
        ...state,
        loadingApproval: false,
        error: action.error
      };
    case REJECT_EXPENSE_SUCCESS:
      return {
        ...state,
        loadingRejection: false,
        expenses: state.expenses.filter((exp) => exp.id !== action.expenseId)
      };
    case REJECT_EXPENSE_ERROR:
      return {
        ...state,
        loadingRejection: false,
        error: action.error
      };
    case LOADING_EXPENSE_RECEIPT:
      return {
        ...state,
        loadingReceipt: true
      };
    case EXPENSE_RECEIPT_SUCCESS:
      return {
        ...state,
        loadingReceipt: false,
        expenseReceipt: action.expenseReceipt
      };
    case EXPENSE_PAYMENT_RECEIPT_SUCCESS:
      return {
        ...state,
        loadingReceipt: false,
        expensePaymentReceipt: action.expenseReceipt
      };
    case EXPENSE_RECEIPT_ERROR:
      return {
        ...state,
        loadingReceipt: false,
        error: action.error
      };
    case STORE_EXPENSE_REPORT_FILTERS:
      return {
        ...state,
        filters: action.filters
      };
    case LOADING_EXPENSE_REPORT_EXPORT:
      return {
        ...state,
        loadingExport: true
      };
    case EXPENSE_REPORT_EXPORT_CLEAR:
      return {
        ...state,
        loadingExport: false
      };
    case GET_VATS:
      return {
        ...state,
        loading_vats: false,
        vats: action.vats
    };
    case LOADING_VATS:
      return {
        ...state,
        loading_vats: true
      };
    case LOADING_EXPENSE_VAT:
      return {
        ...state,
        loading_expense_vat: true
      };
    case EXPENSE_VAT_ERROR:
        return {
          ...state,
          loading_expense_vat: false
        };
    default:
      return state;
  }
};
