import { Storage } from "aws-amplify";
import _ from "lodash";
import moment from "moment";
import * as qs from "qs";
import React from "react";
import BigCalendar from "react-big-calendar";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import { DayPicker } from "react-dates";
import { connect } from "react-redux";
import { toast } from "react-semantic-toasts";
import { Button, Divider, Dropdown, Grid, Icon, Item, Label, Loader, Modal, Popup, Segment, Sidebar } from "semantic-ui-react";
import ability from "../../../abilities/ability";
import Can from "../../../abilities/Can";
import FormModal from "../../../components/core/timereport/FormModal";
import PreferredProjectsModal from "../../../components/core/timereport/PreferredProjectsModal";
import RecapTable from "../../../components/core/timereport/RecapTable";
import ReportFormModal from "../../../components/core/timereport/ReportFormModal";
import IconButton from "../../../components/shared/buttons/IconButton";
import ReusableHeaderWithHelp from '../../../components/shared/ReusableHeaderWithHelp';
import { startGetCutoff } from "../../../store/actions/core/administration/cutoff";
import { startGetTRHolidays } from "../../../store/actions/core/administration/holiday";
import {
  startSearchAllConsultant
} from "../../../store/actions/core/administration/manage_consultant";
import {
  startCreateTrExpense,
  startDeleteTrExpense,
  startEditTrExpense,
  startGetTrExpenses,
  startGetTrExpenseTypes
} from "../../../store/actions/core/timereport/expense";
import {
  startCreateTrHour,
  startDeleteTrHour,
  startDeleteTrHours,
  startEditTrHour,
  startGetTrHours,
  startGetTrHourTypes
} from "../../../store/actions/core/timereport/hour";
import { startGetTrProjectList, startTogglePreferredProject } from "../../../store/actions/core/timereport/project";
import { startGetActivitiesReport, startGetExpensesReport } from "../../../store/actions/core/timereport/reports";
import { startGetCompanies } from "../../../store/actions/shared/company";
import constants from "../../../utils/constants";
import { capitalize } from "../../../utils/stringUtils";

const DragAndDropCalendar = withDragAndDrop(BigCalendar);

require("moment/locale/en-gb");

const localizer = BigCalendar.momentLocalizer(moment);
const culture = "en-gb";

const CODES_FOR_8_HOUR_CHECK = ["NORM", "FESTIVO", "NOTTNORMAL", "SUNDAYNORM"]

export const types = {
  HOURS: "hours",
  EXPENSES: "expenses"
};

export const actions = {
  READ: "read",
  CREATE: "create",
  EDIT: "edit"
};

let permission_code_read = "READ_TR"
let permission_code_write = "CREATE_TIME_REPORT"

var company_details = null;
class TimeReportContainer extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      showSidebar: true,
      expandCalendar: false,

      date: moment(),
      selectedDates: new Set(),

      //TODO user might have multiple CNs, how to handle that case??
      CnList: [],
      currentCN: props.cn,
      currentUserID: props.user_id,
      currentUniqueID: props.unique_id,
      currentName: props.name,
      currentCompany: "",
      type: types.HOURS,
      getEvents: this.getHours,
      action: actions.READ,
      is_dummy: 0,

      // modals state
      showFormModal: false,
      showPreferredProjectsModal: false,
      showReportsModal: false,
      showClearModal: false,

      values: {},
      editable: false, //if false then READ ONLY

      reducedAmount: {},
      reducedTypes: {},
      reducedExpenseTypes: {},
      datesStatus: {},
      expenseStatus: {},
      currency: "",
      amount_error: "",

      fileUploaded: false,
      secondFileUploaded: false,
    };

  }


  componentDidMount() {
    if (this.state.currentCN) {
      this.state.getEvents();
      this.props.getCutoff(this.props.user_id, 0); //quando entro la prima volta l'utente di sicuro non è un dummy
      company_details = this.props.company_details;

      this.props.getCompanies()

      this.setState({
        currency: company_details.currency,
        currentCompany: company_details.code
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (ability.rules.length > 0) {
      // console.log("file: TimeReportContainer.js:129 ~ TimeReportContainer ~ componentWillReceiveProps ~ ability:", ability);

      if (ability.rules.some(rule => rule.subject === "all-timereport" && rule.actions === "time_report:Read")) {
        permission_code_read = "READ_ALL_TR"
      }
      if (ability.rules.some(rule => rule.subject === "all-timereport" && rule.actions === "time_report:Create")) {
        permission_code_write = "CREATE_ALL_TR"
      }
    }

    let events = nextProps.hours;
    let holidays = nextProps.tr_holidays;
    if (holidays && holidays.length > 0) events = events.concat(holidays);
    let amount_key = "hour";

    if (this.state.type === types.EXPENSES) {
      events = nextProps.expenses;
      amount_key = "converted_amount";
    }

    // calculate aggregated values for Sidebar
    let reducedAmount = events.reduce((projects, event) => {
      projects[event.project_code] = (projects[event.project_code] || 0) + event[amount_key];
      return projects;
    }, {});

    let reducedTypes = events.reduce((projects, event) => {
      projects[event.hour_type_code] = (projects[event.hour_type_code] || 0) + event[amount_key];
      return projects;
    }, {});

    let reducedExpenseTypes = events.reduce((projects, event) => {
      projects[event.type_code] = (projects[event.type_code] || 0) + event[amount_key];
      return projects;
    }, {});

    // calculate for each day if all hours have been inserted (i.e. at least 8 hours)
    let datesStatus = events.reduce((dates, event) => {
      if (moment(event.date).day() === 0 || moment(event.date).day() === 6) {
        dates[event.date] = (dates[event.date] || 0) + event.hour;
      } else {
        if (CODES_FOR_8_HOUR_CHECK.some(code => code === event.hour_type_code))
          dates[event.date] = (dates[event.date] || 0) + event.hour;
        else
          dates[event.date] = (dates[event.date] || 0) + 0;
      }
      return dates;
    }, {});

    // check expense status for each day
    let expenseStatus = nextProps.expenses.reduce((dates, event) => {
      dates[event.date] = event.status_code;
      return dates;
    }, {});

    //Object.keys(datesStatus).map((date) => datesStatus[date] = (datesStatus[date] >= 8));

    let name = this.state.currentName;
    let cn = this.state.currentCN;
    let userID = this.state.currentUserID;
    const CnList = this.safelyExtractCnList();
    //console.log("CnList ", CnList);

    const eventsPerDate = Object.values(events.reduce((dates, event) => {
      dates[event.date] = (dates[event.date] || 0) + 1;
      return dates;
    }, {}));
    const maxEvents = _.max(eventsPerDate);

    //console.log("USER ID + CNLIST + cn ", userID, CnList, cn);

    this.setState({
      reducedAmount,
      reducedTypes,
      reducedExpenseTypes,
      datesStatus,
      expenseStatus,
      currentName: !name ? nextProps.name : name,
      currentUserID: !userID ? nextProps.user_id : userID,
      CnList: CnList,
      currentCN: !cn ? nextProps.cn : cn,
      maxHeight: (maxEvents * 12 + 100),
      currentCompany: company_details.code // BugFix: currentCompany = undefined in renderDayContents()
    }, () => {
      if ((!cn && nextProps.cn)) {
        this.state.getEvents();
        this.props.getCutoff(nextProps.user_id, nextProps.is_dummy);
      }
    });
  }


  componentDidUpdate() {
    if (company_details === null) {
      company_details = this.props.company_details;

      this.setState({
        currency: company_details.currency,
        currentCompany: company_details.code
      })
    }
  }

  safelyExtractCnList = () => {
    let CnList = [];
    let cnIdProfiles = this.props.user_details;
    if (cnIdProfiles) {
      _.toArray(
        _.mapValues(cnIdProfiles, (o) => {
          CnList.push({
            key: o.cn_id,
            value: o.cn_id,
            text: "CN " + o.cn_id + " ( " + (o.name ? o.name : this.state.currentName) + " " + (o.surname ? o.surname : "") + " )",
            is_dummy: o.is_dummy,
            user_id: o.user_id,
            name: o.name + " " + o.surname,
            company_code: o.company_code
          });
        })
      );
    }
    return CnList;
  };

  // example implementation of a wrapper
  DateCellWrapper = (data) => {

    let icon = "check circle outline";
    let color = "grey";
    const queryString = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    if (queryString.icon) {
      switch (queryString.icon) {
        case "fire":
          icon = "fire";
          color = "orange";
          break;
        case "music":
          icon = "music";
          color = "violet";
          break;
        default:
          icon = "check circle outline";
          color = "";
      }
    }

    return (
      <div style={{ margin: "2px" }}>
        <span style={{ float: "left" }}>{data.label}</span>
        {this.state.selectedDates.has(moment(data.date).format("YYYY-MM-DD")) &&
          <Icon size="large" color={color} name={icon} />}
        {!data.isOffRange &&
          <Button basic size="mini" icon onClick={() => this.addSingleEvent(data.date)}><Icon name="plus" /></Button>
        }
        {data.children}
      </div>
    );
  };

  changeType = () => {
    const type = this.state.type === types.HOURS ? types.EXPENSES : types.HOURS;
    const getEvents = this.state.type === types.HOURS ? this.getExpenses : this.getHours;
    this.setState({
      type,
      getEvents
    });
    getEvents();
  };

  /**
   * CN selection callback, reloads user hours
   * @param e  change event
   * @param value current value selected from dropdown
   */
  changeCN = (e, { value }) => {
    //console.log("VALUE ", value);
    let is_dummy = this.state.CnList.find(o => o.value === value).is_dummy;
    let user_id = this.state.CnList.find(o => o.value === value).user_id;
    let name = this.state.CnList.find(o => o.value === value).name;
    let company_code = this.state.CnList.find(o => o.value === value).company_code;
    // console.log("file: TimeReportContainer.js:309 ~ TimeReportContainer ~ company_code:", company_code)
    //console.log("is_dummy " + is_dummy);
    this.setState({ currentCN: value, is_dummy: is_dummy, currentUserID: user_id, currentName: name, currentCompany: company_code }, () => { return (this.state.getEvents(), this.props.getCutoff(user_id, is_dummy)); });
    return;
  };

  changeCNadmin = (e, { value }) => {
    //console.log("VALUE ", value);
    let is_dummy = this.props.consultants.find(o => o.value === value).is_dummy;
    let user_id = this.props.consultants.find(o => o.value === value).user_id;
    let name = this.props.consultants.find(o => o.value === value).name;
    let company_code = this.props.consultants.find(o => o.value === value).company_code;
    // console.log("file: TimeReportContainer.js:321 ~ TimeReportContainer ~ company_code:", company_code)
    //console.log("is_dummy " + is_dummy, " user_id " + user_id);
    this.setState({ currentCN: value, is_dummy: is_dummy, currentUserID: user_id, currentName: name, currentCompany: company_code }, () => { return (this.state.getEvents(), this.props.getCutoff(user_id, is_dummy)) });
    return;
  };

  /**
   * Fetch Hours events from API for Calendar, but filtered with current year and month
   */
  getHours = () => {
    const params = {
      year: this.state.date.year(),
      month: this.state.date.month() + 1,
      is_dummy: this.state.is_dummy
    };
    this.props.getHours(this.state.currentCN, params);
    this.props.getHolidays(params, this.state.currentUserID);
  };

  /**
   * Fetch Expense events from API for Calendar, but filtered with current year and month
   */
  getExpenses = () => {
    const params = {
      year: this.state.date.year(),
      month: this.state.date.month() + 1,
      is_dummy: this.state.is_dummy
    };
    this.props.getExpenses(this.state.currentCN, params);
  };

  handleSubmit = () => {

    const { values, type, action } = this.state;

    const refetchParams = {
      year: this.state.date.year(),
      month: this.state.date.month() + 1,
      is_dummy: this.state.is_dummy
    };

    const project = values.project.split("$");
    //console.log("PROJECT", project);


    if (action === actions.EDIT) {
      if (type === types.HOURS) {

        const hourType = values.hour_type.split("$");
        //console.log("HOUR TYPE ", hourType);

        let newEvent = {
          "project_id": Number(project[0]),
          "project_code": project[1],
          "project_name": project[2],
          "project_color": project[3],
          "tr_label": project[4],
          "hour_type_id": Number(hourType[0]),
          "hour_type_code": hourType[1],
          "date": values.date,
          "hour": parseFloat(values.hour_amount > 24 ? 24 : values.hour_amount),
          "office_location": values.office_location,
          "comment": values.comment,
          "is_dummy": this.state.is_dummy,
          "user_id": this.state.currentUserID,
          "sales_level_id": values.sales_level_id
        };

        this.props.editTrHour(this.state.currentCN, values.id, newEvent, refetchParams);

      } else if (type === types.EXPENSES) {

        //console.log("EDIT EXPENSE", values);

        const expenseType = values.expense_type.split("$");

        const double_receipt = Number(expenseType[4]) && !values.company_paid ? Number(expenseType[7]) : 0;
        let newEvent = {
          "project_id": Number(project[0]),
          "project_code": project[1],
          "project_name": project[2],
          "project_color": project[3],
          "tr_label": project[4],
          "expense_type_id": Number(expenseType[0]),
          "expense_type_code": expenseType[1],
          "date": values.date,
          "amount": parseFloat(values.expense_amount !== values.amount ? values.expense_amount : values.amount),
          "company_paid": !!values.company_paid,
          "rebillable": !!values.rebillable,
          "notes": (values.notes || ""),
          "allowance": Number(expenseType[2]),
          "auto_approve": Number(expenseType[3]),
          "need_receipt": Number(expenseType[4]),
          "double_receipt": double_receipt,
          "receipt_key": values.receipt_key,
          "second_receipt_key": values.second_receipt_key,
          "is_dummy": this.state.is_dummy,
          "user_id": this.state.currentUserID,
          "sales_level_id": values.sales_level_id
        };

        this.props.editTrExpense(this.state.currentCN, values.id, newEvent, refetchParams, values.file, values.secondReceipt);
      }

      // else is an INSERT
    } else {


      let params = {
        "project_id": Number(project[0]),
        "dates": [...this.state.selectedDates]
      };

      if (type === types.HOURS) {

        const hourType = values.hour_type.split("$");

        params.hour_type_id = Number(hourType[0]);
        params.hour = Number(values.hour_amount > 24 ? 24 : values.hour_amount);
        params.office_location = (values.office_location || "");
        params.comment = (values.comment || "");
        params.user_id = this.state.currentUserID;
        params.is_dummy = this.state.is_dummy;
        params.sales_level_id = values.sales_level_id;
        //console.log("DATES ", params.dates);

        this.props.createTrHour(this.state.currentCN, params, refetchParams);

      } else if (type === types.EXPENSES) {

        const expenseType = values.expense_type.split("$");

        params.expense_type_id = Number(expenseType[0]);
        params.amount = parseFloat(values.expense_amount);
        params.company_paid = values.company_paid ? 1 : 0;
        params.rebillable = values.rebillable ? 1 : 0;
        params.notes = (values.notes || "");
        //params.allowance = expenseType[2];
        //params.autoApprove = expenseType[3];
        //params.needReceipt = expenseType[4];
        params.sales_level_id = values.sales_level_id;
        params.user_id = this.state.currentUserID;
        params.is_dummy = this.state.is_dummy;

        const double_receipt = Number(expenseType[4]) && !values.company_paid ? Number(expenseType[7]) : 0;
        params.double_receipt = double_receipt

        this.props.createTrExpense(this.state.currentCN, params, refetchParams, values.file, values.secondReceipt);
      }
    }
    this.setState({ selectedDates: new Set(), values: {}, editing: false, editable: false, fileUploaded: false, secondFileUploaded: false });
    // console.log('SUMBIT: fileUploaded', this.state.fileUploaded)
    this.closeModal();
  };

  handleDeleteEvent = () => {
    const userID = this.state.currentUserID;
    const eventId = this.state.values.id;
    const isDummy = this.state.is_dummy;
    const S3Key = this.state.values.receipt_key ? this.state.values.receipt_key : ''
    const secondReceiptS3Key = this.state.values.second_receipt_key ? this.state.values.second_receipt_key : ''
    //console.log("EVENT ID ", eventId);
    if (this.state.type === types.HOURS) {
      this.props.deleteTrHour(userID, isDummy, eventId);
    } else if (this.state.type === types.EXPENSES) {
      this.props.deleteTrExpense(userID, isDummy, eventId, S3Key, secondReceiptS3Key);
    }

    this.setState({ values: {} });
    this.closeModal();

  };

  //hours in this case refer to selected dates for simplicity
  handleDeleteEvents = () => {
    const userID = this.state.currentUserID;
    const events = [...this.state.selectedDates].join(",");
    //console.log("I'm deleting ", events, " of ", userID, " and is dummy : ", this.state.is_dummy);
    if (this.state.type === types.HOURS)
      this.props.deleteTrHours(userID, this.state.is_dummy, events);
    this.closeModal();
  };

  handleEdit = () => {
    const { values, type } = this.state;
    //console.log("values ", values);

    let editValues = {};

    if (type === types.HOURS) {
      this.fetchHourProps();
      editValues = {
        hour_amount: values.hour,
        hour_type: values.hour_type_id + "$" + values.hour_type_code,
        project: values.project_id + "$" + values.project_code + "$" + values.project_name + "$" + values.project_color + "$" + values.tr_label
      };
    } else if (type === types.EXPENSES) {
      let unit = values.unit === this.state.currency ? "" : values.unit;
      this.fetchExpenseProps();
      editValues = {
        expense_amount: values.amount.toFixed(2),
        expense_type: values.type_id + "$" + values.type_code + "$" + values.allowance + "$" + values.auto_approve + "$" + values.need_receipt + "$" + unit + "$" + "" + "$" + (values.double_receipt ? values.double_receipt : 1),
        project: values.project_id + "$" + values.project_code + "$" + values.project_name + "$" + values.project_color + "$" + values.tr_label,
        company_paid: !!values.company_paid,
        rebillable: !!values.rebillable,
      };

      //console.log("EditValues", editValues);
    }

    this.setState(prevState => (
      { editable: true, action: actions.EDIT, values: Object.assign(prevState.values, editValues) })
    );
  };

  fetchHourProps = () => {
    const userID = this.state.currentUserID;
    this.props.getUserProjects(userID, this.state.is_dummy);
    this.props.getUserHourTypes(userID, this.state.is_dummy);
  };

  fetchExpenseProps = () => {
    const userID = this.state.currentUserID;
    this.props.getUserProjects(userID, this.state.is_dummy);
    this.props.getUserExpenseTypes(userID, this.state.is_dummy);
  };

  /**
   * Handle click on "+" icon in a cell. Open the new activity/expense form with only the selected day.
   * @param date {String} String representation of selected date with format **YYYY-MM-DD**
   */
  addSingleEvent = (date) => {
    this.setState({ selectedDates: new Set([moment(date).format("YYYY-MM-DD")]) },
      this.handleNewEventClick);
  };

  /**
   * Check if any of the selected Dates in component state are before the cutoff date.
   * @returns {boolean} true if at least one forbidden date has been selected, false otherwise
   */
  checkCutoffForSelectedDates = () => {
    let cutoffDate = moment(this.props.cutoff, "YYYY-MM-DD");
    let cutoffFail = false;
    if (this.state.selectedDates) {
      this.state.selectedDates.forEach((date) => {
        let valueDate = moment(date, "YYYY-MM-DD");
        if (valueDate.isBefore(cutoffDate, 'day')) {
          cutoffFail = true;
        }
      });
    }
    return cutoffFail;
  };

  handleNewEventClick = () => {
    if (this.state.selectedDates.size > 0) {
      if (this.checkCutoffForSelectedDates()) {
        toast(
          {
            title: "Timereport",
            description: "The Timereport is closed for the selected dates",
            icon: "none",
            color: "teal",
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: 'fly left'
          });
      } else {
        if (this.state.type === types.HOURS) {
          this.fetchHourProps();
        } else {
          this.fetchExpenseProps();
        }
        this.setState({ showFormModal: true, values: {}, editable: true, action: "create" });
      }
    } else {
      toast(
        {

          title: "Timereport",
          description: "Please select at least one date!",
          icon: "none",
          color: "teal",
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
  };

  /**
   * Close any modal that is currently visible.
   * As a plus, clear all selected dates (TODO might not be necessary)
   */
  closeModal = () => {

    this.setState({
      showFormModal: false,
      showPreferredProjectsModal: false,
      showReportsModal: false,
      showClearModal: false,
      selectedDates: new Set(),
      values: {},
      amount_error: "",
      fileUploaded: false,
      secondFileUploaded: false
    });
  };

  /**
   * Open Preferred Projects Modal for setting / unsetting preferred projects.
   * Load user associated projects only if not already loaded
   */
  showPreferredProjectsModal = () => {
    if (this.props.projects.length === 0) this.props.getUserProjects(this.state.currentUserID, this.state.is_dummy);
    this.setState({
      showPreferredProjectsModal: true
    });
  };

  clearAllModal = () => {
    const hours = this.props.hours ? this.props.hours : [];
    const expenses = this.props.expenses ? this.props.expenses : [];
    let currentDates = this.state.type === types.HOURS ? new Set(hours.map(d => d.date)) : new Set(expenses.map(d => d.date));
    let intersection = new Set([...currentDates].filter(x => this.state.selectedDates.has(x)));
    //console.log("INTERSECTION DATES ", currentDates, intersection);
    if (this.state.selectedDates.size > 0 && intersection.size > 0) {
      if (this.checkCutoffForSelectedDates()) {
        toast(
          {
            title: "Timereport",
            description: "The Timereport is closed for the selected dates.",
            icon: "none",
            color: "teal",
            time: constants.TOAST_WARNING_TIMEOUT,
            animation: 'fly left'
          }
        );
      } else {
        this.setState({ showClearModal: true, editable: true });
      }
    } else if (intersection.size === 0) {
      this.setState({ selectedDates: new Set() });
    } else {
      toast(
        {

          title: "Timereport",
          description: "Please select at least one date!",
          icon: "none",
          color: "teal",
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left'
        }
      );
    }
  };

  /**
   * Open Reports generation modal
   */
  showReportsModal = () => {
    this.setState({
      showReportsModal: true
    });
  };

  toggleExpandCalendar = () => {
    this.setState((prevState) => {
      return {
        expandCalendar: !prevState.expandCalendar
      };
    });

  };

  /**
   * Hide / show sidebar on the right
   */
  toggleSideBar = () => {
    this.setState((prevState) => {
      return {
        showSidebar: !prevState.showSidebar
      };
    });
  };

  onNavigate = () => {
    // needed by calendar - do nothing
  };

  /**
   * Move to next month both mini calendar and main Calendar
   */
  onNextMonthClick = () => {
    this.setState((state) => ({
      date: moment(state.date).add(1, "M"),
      selectedDates: new Set()
    }), this.state.getEvents);
  };

  /**
   * Move to previous month both mini calendar and main Calendar
   */
  onPrevMonthClick = () => {
    this.setState((state) => ({
      date: moment(state.date).subtract(1, "M"),
      selectedDates: new Set()
    }), this.state.getEvents);
  };

  onMiniCalendarDayClick = (date) => {
    if (this.state.type === types.HOURS) {
      const { datesStatus } = this.state;
      if (!datesStatus) return "";
    } else {
      const { expenseStatus } = this.state;
      if (!expenseStatus) return "";
    }
    this.addSingleEvent(date.format("YYYY-MM-DD"));
  };

  renderDayContents = (date) => {

    const { datesStatus } = this.state;
    // console.log("file: TimeReportContainer.js:736 ~ TimeReportContainer ~ this.state.currentCompany:", currentCompany);

    if (!datesStatus) return "";
    // AEP-182: rimozione hardcode che impedisce alle company diverse da TESPA di vedere correttamente la percentuale di completamento del calendar sul Time Report.
    if (datesStatus[date.format("YYYY-MM-DD")] === 8) {
      // console.log("file: TimeReportContainer.js:741 ~ TimeReportContainer ~ currentCompany:", currentCompany)
      return <span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="teal" name="check" /></span>;
    } else if (datesStatus[date.format("YYYY-MM-DD")] > 8) {
      return <div><Popup content="More than 8 hours!" trigger={<span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="orange" name="exclamation triangle" /></span>} /></div>;
    } else {
      return (
        <span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="red" name="cancel" /></span>
      );
    }
  };

  renderExpenseContents = (date) => {
    const { expenseStatus } = this.state;

    if (!expenseStatus) return "";
    if (expenseStatus[date.format("YYYY-MM-DD")] === "PENDING") {
      return <div><Popup content="Pending" trigger={<span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="orange" name="dollar" /></span>} /></div>;
    } else if (expenseStatus[date.format("YYYY-MM-DD")] === "MR") {
      return <div><Popup content="Missing Receipt" trigger={<span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="yellow" name="dollar" /></span>} /></div>;
    } else if (expenseStatus[date.format("YYYY-MM-DD")] === "OK" || expenseStatus[date.format("YYYY-MM-DD")] === "CLOSED") {
      return <div><Popup content="Approved" trigger={<span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="teal" name="dollar" /></span>} /></div>;
    } else if (expenseStatus[date.format("YYYY-MM-DD")] === "KO_PM" || expenseStatus[date.format("YYYY-MM-DD")] === "KO_HR") {
      return <div><Popup content="Rejected" trigger={<span style={{ "fontSize": "0.9em" }}>{date.format("DD")}<Icon color="red" name="cancel" /></span>} /></div>;
    } else {
      return (
        <span style={{ "fontSize": "0.9em" }}>{date.format("DD")}</span>
      );
    }
  }

  moveEvent = (event) => {
    // TODO copy event if CTRL key is pressed
    //console.log("event ", event, event.sf_event_id);
    const newDate = moment(event.start).format("YYYY-MM-DD");
    const actualDate = new Date(newDate).getMonth()
    const today_month = new Date(event.event.date).getMonth()
    if (moment(newDate).isBefore(moment(this.props.cutoff)) || moment(event.event.date).isBefore(moment(this.props.cutoff))) {
      toast(
        {
          title: "Timereport",
          description: "The Timereport is closed for the selected dates.",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    } else if (event.event.sf_event_id) {
      toast(
        {

          title: "Timereport",
          description: "You cannot move your time off!",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    } else if (newDate !== event.event.date) {
      const refetchParams = {
        year: this.state.date.year(),
        month: this.state.date.month() + 1,
        is_dummy: this.state.is_dummy
      };

      const newEvent = {
        "tr_label": event.event.tr_label,
        "project_code": event.event.project_code,
        "project_name": event.event.project_name,
        "project_color": event.event.project_color,
        "hour_type_code": event.event.hour_type_code,
        "project_id": Number(event.event.project_id),
        "hour_type_id": Number(event.event.hour_type_id),
        "hour": parseFloat(event.event.hour),
        "office_location": event.event.office_location,
        "comment": event.event.comment,
        "user_id": this.state.currentUserID,
        "is_dummy": this.state.is_dummy,
        "sales_level_id": event.event.sales_level_id
      };

      //console.log("CTRLKEY PRESSED: ", window.event.ctrlKey);
      if (window.event.ctrlKey && this.state.type === types.HOURS) {
        //console.log("cliccato ctrlKey");
        newEvent.dates = [moment(event.start).format("YYYY-MM-DD")];
        this.props.createTrHour(this.state.currentCN, newEvent, refetchParams);
      } else if (this.state.type === types.HOURS) {
        if (actualDate === today_month) {
          newEvent.date = moment(event.start).format("YYYY-MM-DD");
          this.props.editTrHour(this.state.currentCN, event.event.id, newEvent, refetchParams);
        } else {
          toast(
            {
              title: "Timereport",
              description: "Invalid date selected.",
              type: "error",
              icon: "file",
              time: constants.TOAST_ERROR_TIMEOUT,
              animation: 'fly left'
            }
          );
        }
      }
      //console.log("CTRLKEY PRESSED: ", window.event.ctrlKey);

    }

  };

  onSelectSlot = (slot) => {
    //console.log("onSelectSlot ", slot);
    if (slot.action === "doubleClick") {
      let date = moment(slot.start).format("YYYY-MM-DD");

      // Only act on slots in the currently considered month
      if (moment(slot.start).month() === this.state.date.month()) {
        if (this.state.selectedDates.has(date)) {
          // remove element from selected
          this.setState((state) => {
            const newSet = new Set(state.selectedDates);
            newSet.delete(date);

            return { selectedDates: newSet };
          });
        } else {
          this.setState((state) => {
            return { selectedDates: new Set(state.selectedDates).add(date) };
          });
        }
      }
    } else if (slot.action === "select") {
      this.setState((state) => {
        const newSet = new Set(state.selectedDates);
        slot.slots.forEach((s) => {
          const formattedDate = moment(s).format("YYYY-MM-DD");

          // Only act on slots in the currently considered month
          if (moment(s).month() === this.state.date.month()) {
            if (newSet.has(formattedDate)) {
              newSet.delete(formattedDate);
            } else {
              newSet.add(formattedDate);
            }
          }

        });
        return { selectedDates: newSet };
      });
    }
  };

  onSelectEvent = (event, e) => {
    //console.log("SELECT event", event);
    let new_selected_date = new Set()
    new_selected_date.add(event.date)
    if (this.state.type === types.EXPENSES && !!event.receipt_key) {
      Promise.all([
        Storage.get(event.receipt_key, {
          level: "public"
        }),
        Storage.get(event.second_receipt_key, {
          level: "public"
        })
      ])
        .then(([recepitUrl, secondReceiptUrl]) => {
          if (event.unit === null)
            event.unit = this.state.currency;

          this.setState({
            values: event,
            showFormModal: true,
            editable: false,
            action: actions.READ,
            expenseReceipt: recepitUrl,
            expenseSecondReceipt: secondReceiptUrl,
            selectedDates: new_selected_date
          });
        })
        .catch(error => {
          //console.log("[ERROR] cannot fetch receipt from S3", error);
        });
    } else {
      if (event.unit === null)
        event.unit = this.state.currency;

      this.setState({
        values: event,
        showFormModal: true,
        editable: false,
        action: actions.READ,
        expenseReceipt: undefined,
        expenseSecondReceipt: undefined,
        selectedDates: new_selected_date
      });
    }

  };

  customDayPropGetter = date => {
    const cellStyle = {};
    const { datesStatus } = this.state;
    //console.log("DATEEEEES ", {datesStatus}, date, moment(date));

    if ((moment(date).day() === 0 || moment(date).day() === 6) && moment(date).month() === this.state.date.month()) {
      cellStyle.className = "rbc-weekend-cell";
    } else if (datesStatus[moment(date).format("YYYY-MM-DD")] > 8 && this.state.currentCompany === "TESPA") {
      cellStyle.className = "rbc-warning-cell";
    }
    return cellStyle;
  };

  eventPropGetter = (event) => {
    //console.log(event);
    switch (event.status_code) {
      case "MR":
      case "PENDING":
        return {
          style: {
            backgroundColor: event.project_color,
            opacity: 0.4
          }
        };
      case "CLOSED":
      case "OK":
        return {
          style: {
            backgroundColor: event.project_color
          }
        };
      case "KO":
      case "KO_PM":
      case "KO_HR":
        return {
          style: {
            backgroundColor: "darkred"
          }
        };
      default:
        return {
          style: {
            backgroundColor: "#00b5ad"
          }
        };

    }
  };

  titleAccessor = (event) => {
    if (this.state.type === types.HOURS) {
      return (`${event.hour}h - ${event.tr_label}`);
    } else {
      return (`${event.amount.toFixed(2)} - ${event.tr_label}`);
    }

  };

  /**
   * Handle value changes in modal form
   * @param e Change event
   * @param data contains all Input props (name,value, checked, etc...)
   */
  handleChange = (e, data) => {
    //console.log("HANDLE CHANGE, ", data);
    if (data.value === "" && data.checked === undefined) {
      data.value = undefined;
    } else if (data.checked !== undefined) data.value = data.checked;


    if (data.name === "expense_amount") {
      //console.log(data.name);
      const re = /^\d+(\.\d+)?$/;
      if (!re.test(String(data.value).toLowerCase()) || String(data.value).toLowerCase().search("e") >= 0) {
        this.setState({
          amount_error: "Invalid amount!"
        });
      } else {
        this.setState({
          amount_error: ""
        });
      }
    }

    if (data.name === "hour_amount") {
      //console.log(data.name);
      const re = /^\d+(\.\d+)?$/;
      if (!re.test(String(data.value).toLowerCase()) || String(data.value).toLowerCase().search("e") >= 0 || data.value > 24) {
        this.setState({
          amount_error: "Invalid amount!"
        });
      } else {
        this.setState({
          amount_error: ""
        });
      }
    }

    let values = {
      ...this.state.values,
      [data.name]: data.value
    };

    if (data.name === "expense_type") {
      //console.log("sto capendo il tipo");
      console.log(data);
      const splittedTypeFields = data.value ? data.value.split("$") : [];

      if (splittedTypeFields[5] !== "") {
        values.unit = splittedTypeFields[5];
      }
      else {
        values.unit = this.state.currency;
      }

      //console.log(splittedTypeFields);
      // If "allowance"
      if (splittedTypeFields[2] === "1") {
        values.company_paid = 1;
        values.expense_amount = 1.0;
        values.allowance = 1;
      } else {
        values.company_paid = parseInt(splittedTypeFields[6]) //imposto il valore di default per il paid
        values.allowance = 0;
        //values.expense_amount = "";
      }
      //console.log(splittedTypeFields);
    }
    if (data.name === "project") {
      values.sales_level_id = data.options.find(o => o.value === data.value).sales_level_id;
    }

    if (data.name === "company_paid") {
      this.setState({ secondFileUploaded: false })
    }


    this.setState({
      values: values
    });
  };

  handleFileUpload = (isSecondReceipt) => {
    console.log("handle file", isSecondReceipt);

    if (!isSecondReceipt) this.setState({ fileUploaded: true });
    if (isSecondReceipt) this.setState({ secondFileUploaded: true });
  };

  handleFile = (event, isSecondReceipt) => {
    const file = event.target.files[0];
    // heck file extension
    if (!file || ["image/jpeg", "image/png", "application/pdf", "image/JPG"].indexOf(file.type) === -1) {
      toast(
        {
          title: "Expense Receipt",
          description: "Invalid File! Accepted types are: jpeg, png, pdf, JPG",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        }
      );
    } else {
      if (isSecondReceipt) {
        this.setState(prevState => ({
          values: {
            ...prevState.values,
            secondReceipt: file,
          }
        }));
      } else {
        this.setState(prevState => ({
          values: {
            ...prevState.values,
            file: file,
          }
        }));
      }
      this.handleFileUpload(isSecondReceipt);
    }

    // console.log('LOG FILE: ', event.target.files[0])
    // console.log('STATE: ', this.state)
  };
  handleReceipt = (event) => {
    this.handleFile(event, false)
  };
  handleSecondReceipt = (event) => {
    this.handleFile(event, true)
  };

  // set/unset Preferred status of a project

  setUserPreferredProject = (projectId, status, sales_level_id) => {
    let user_id = this.props.user_id;
    //console.log("user_id preferred: ", user_id);
    status = (status === 1 ? 0 : 1);
    this.props.setUserPreferredProject(user_id, projectId, status, sales_level_id, this.state.is_dummy);
  };

  getReport = (state) => {

    // Prepare data.
    const cn_code = this.state.currentCN;
    const params = {
      year: state.year,
      month: state.month,
      is_dummy: this.state.is_dummy
    };
    const user_id = this.state.currentUserID;
    const name = this.state.currentName;
    const currency = this.state.currency;
    // Discriminate accordingly between the types.
    const type = state.type;
    if (type === types.EXPENSES) {
      this.closeModal();
      this.props.getExpensesReport(cn_code, params, user_id, currency, name);
    } else if (type === types.HOURS) {
      this.closeModal();
      this.props.getActivitiesReport(cn_code, params, name);
    }
  };


  render() {

    const { expandCalendar, showSidebar, type, action, reducedAmount, reducedTypes, selectedDates, reducedExpenseTypes } = this.state;
    let holidays = this.props.tr_holidays;
    let events = this.props.hours;
    if (holidays && holidays.length > 0) {
      events = events.concat(holidays);
    }
    let loading = this.props.loading_hours;

    if (type === types.EXPENSES) {
      events = this.props.expenses;
      loading = this.props.loading_expenses;
    }
    //console.log("selectedDates ", this.state.selectedDates, " events ", events, " holidays ", holidays);
    //console.log("props timereport container: ", this.props);

    const company = this.props.companies.find(x => x.code === this.props.company_details.code)

    return (

      <Sidebar.Pushable as={Segment}>
        <Can I="time_report:Read" a='my-timereport'>
          <Sidebar direction='right'
            animation='overlay'
            icon='labeled'
            style={{ width: "320px" }}
            visible={this.state.showSidebar}>

            <Popup
              content={"Here you can view the recap of your time/expenses reporting. Clicking on a single day, you can report your hour/expense."}
              trigger={(
                <Label style={{ margin: "1rem" }} color="teal" circular>
                  ?
                </Label>
              )}
            />

            {!this.props.loading_hours && type === types.HOURS && this.state.currentCN && this.state.currentUserID && !this.props.loading_tr ?
              <DayPicker numberOfMonths={1}
                hideKeyboardShortcutsPanel={true}
                onNextMonthClick={this.onNextMonthClick}
                onPrevMonthClick={this.onPrevMonthClick}
                onDayClick={this.onMiniCalendarDayClick}
                renderDayContents={this.renderDayContents}
                initialVisibleMonth={() => this.state.date}
              />
              : !this.props.loading_expenses && type === types.EXPENSES && this.state.currentCN ?
                <DayPicker numberOfMonths={1}
                  hideKeyboardShortcutsPanel={true}
                  onNextMonthClick={this.onNextMonthClick}
                  onPrevMonthClick={this.onPrevMonthClick}
                  onDayClick={this.onMiniCalendarDayClick}
                  renderDayContents={this.renderExpenseContents}
                  initialVisibleMonth={() => this.state.date}
                />
                :
                <div style={{ height: "293px", width: "318px" }}>
                  <Loader style={{ top: "50%" }} active inline='centered' />
                </div>
            }

            {type === types.HOURS &&
              <div>
                <RecapTable header='Projects Recap' values={reducedAmount} unit='days' type={type} />
                <RecapTable header='Hour Types Recap' values={reducedTypes} unit='days' type={type} />
                <div>
                  <Divider style={{ marginTop: "4rem" }} horizontal>
                    Timereport Hours Legend
                  </Divider>
                </div>
              </div>
            }

            {type === types.EXPENSES &&
              <div>
                <RecapTable header='Projects Recap' values={reducedAmount} unit={this.state.currency} type={type} />
                <RecapTable header='Expense Types Recap' values={reducedExpenseTypes} unit={this.state.currency} type={type} />
                <div>
                  <Divider style={{ marginTop: "4rem" }} horizontal>
                    Timereport Expenses Legend
                  </Divider>
                </div>
              </div>
            }

            <Item.Group >
              <Item>
                <Item.Content verticalAlign='middle' style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ margin: "auto 10px" }}>
                    <span style={{ width: "20px", height: "20px", borderRadius: "5px", background: "rgba(216,126,0,.4)", display: "inline-block", marginRight: '10px' }} />
                    <span style={{ verticalAlign: 'super' }}>Pending Corporate</span>
                  </div>
                  <div>
                    <span style={{ width: "20px", height: "20px", borderRadius: "5px", background: "rgba(0,181,173,.4)", display: "inline-block", marginRight: '10px' }} />
                    <span style={{ verticalAlign: 'super' }}>Pending Project</span>
                  </div>

                </Item.Content>
              </Item>
              <Item>
                <Item.Content verticalAlign='middle' style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ margin: "auto 10px" }}>
                    <span style={{ width: "20px", height: "20px", borderRadius: "5px", background: "rgba(216,126,0,1)", display: "inline-block", marginRight: '10px' }} />
                    <span style={{ verticalAlign: 'super' }}>Approved Corporate</span>
                  </div>
                  <div>
                    <span style={{ width: "20px", height: "20px", borderRadius: "5px", background: "rgba(0,181,173,1)", display: "inline-block", marginRight: '10px' }} />
                    <span style={{ verticalAlign: 'super' }}>Approved Project</span>
                  </div>
                </Item.Content>
              </Item>
              <Item>
                <Item.Content verticalAlign='middle' style={{ display: "flex" }}>
                  <div style={{ margin: "auto 10px" }}>
                    <span style={{ width: "20px", height: "20px", borderRadius: "5px", background: "darkred", display: "inline-block", marginRight: '10px' }} />
                    <span style={{ verticalAlign: 'super' }}>Rejected</span>
                  </div>
                </Item.Content>
              </Item>
            </Item.Group>



          </Sidebar>
        </Can>
        <Sidebar.Pusher>
          <div className={"timereport-container " + (showSidebar ? "timereport-show-sidebar" : "")}>
            <ReusableHeaderWithHelp title={"Time Report - " + capitalize(type)} link="/help/time-report" />

            <Segment>
              <Grid stackable>
                <Grid.Row>
                  <Grid.Column width={10}>
                    <Can I="time_report:Create" a='my-timereport'>
                      {type === types.HOURS &&
                        <React.Fragment>
                          <IconButton label='New Activity' disabled={selectedDates.size === 0} icon='add circle'
                            onClick={this.handleNewEventClick} />
                          <IconButton label='Expenses Calendar' icon='dollar' onClick={this.changeType} />
                        </React.Fragment>
                      }
                      {
                        type === types.EXPENSES &&
                        <React.Fragment>
                          <IconButton label='New Expense' disabled={selectedDates.size === 0} icon='add circle'
                            onClick={this.handleNewEventClick} />
                          <IconButton label='Hours Calendar' icon='calendar' onClick={this.changeType} />
                        </React.Fragment>
                      }
                    </Can>


                    {!expandCalendar &&
                      <IconButton label='Expand Calendar'
                        icon='expand arrows alternate'
                        disabled={events.length === 0}
                        onClick={this.toggleExpandCalendar} />
                    }
                    {expandCalendar &&
                      <IconButton label='Collapse Calendar' icon='compress' onClick={this.toggleExpandCalendar} />
                    }
                    <IconButton label='Reports' icon='chart bar' onClick={this.showReportsModal} />
                    <IconButton label='Favourite Projects' icon='star' onClick={this.showPreferredProjectsModal} />
                    <Can I="time_report:Create" a='my-timereport'>
                      <IconButton label='Clear Selected' disabled={selectedDates.size === 0 || type === types.EXPENSES} icon='trash'
                        onClick={this.clearAllModal} />
                    </Can>

                  </Grid.Column>
                  <Grid.Column width={6}>

                    <Button icon basic floated="right" onClick={this.toggleSideBar}><Icon name="sidebar" /></Button>
                    {<Can I="time_report:Read" a="all-timereport">
                      {() => (

                        <Dropdown
                          selection
                          search
                          placeholder={"Search consultants"}
                          loading={this.props.loading_consultants}
                          options={this.props.consultants}
                          onChange={this.changeCNadmin}
                          onSearchChange={(e) => {
                            const query = e.target.value;
                            if (query.length > 2) this.props.searchConsultant({ query });
                          }
                          }
                          style={{ float: "right", margin: "0 10px" }} />
                      )}
                    </Can>}
                    {this.state.CnList && this.state.CnList.length > 1 &&
                      <Can not I="time_report:Read" a="all-timereport">
                        <Dropdown selection
                          style={{ float: "right", margin: "0 10px" }}
                          value={this.state.currentCN}
                          options={this.state.CnList}
                          onChange={this.changeCN}
                        />
                      </Can>}
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>

            <Segment basic loading={loading || !this.state.currentCN || !this.state.currentUserID}>
              <Popup
                content={"Select month you want to report activities and expenses for. You can select multiple days by click and drag your mouse cursor."}
                trigger={(
                  <Label style={{ position: "relative", left: "50%", transform: "translateX(-50%)" }} color="teal" circular>
                    ?
                  </Label>
                )}
              />
              <div style={{ textAlign: "center" }}>

                {!this.state.showSidebar && //only show if the sidebar is hidden
                  <Button icon basic onClick={this.onPrevMonthClick}><Icon
                    name="arrow left" /></Button>
                }
                <span className="ui header" style={{ padding: "10px" }}>{this.state.date.format("MMMM YYYY")}</span>
                {!this.state.showSidebar && //only show if the sidebar is hidden
                  <Button icon basic onClick={this.onNextMonthClick}><Icon
                    name="arrow right" /></Button>
                }
              </div>
              {!expandCalendar &&
                <DragAndDropCalendar
                  localizer={localizer}
                  culture={culture}
                  toolbar={false}
                  events={events}
                  date={this.state.date.toDate()}

                  defaultView="month"
                  views={["month"]}
                  popup

                  selectable
                  onSelectSlot={this.onSelectSlot}
                  onSelectEvent={this.onSelectEvent}
                  onNavigate={this.onNavigate}
                  onEventDrop={this.moveEvent}

                  dayPropGetter={this.customDayPropGetter}
                  startAccessor={(event) => moment(event.date, "YYYY-MM-DD").toDate()}
                  endAccessor={(event) => moment(event.date, "YYYY-MM-DD").toDate()}
                  titleAccessor={this.titleAccessor}
                  eventPropGetter={this.eventPropGetter}

                  components={{
                    event: MyEvent,
                    month: {
                      dateHeader: this.DateCellWrapper

                    },

                    eventWrapper: ({ event, children }) => (
                      <div
                        onContextMenu={

                          e => {
                            //create an alert at right click of the mouse
                            //alert(`${event.project_name} is clicked.`);

                            e.preventDefault();
                          }
                        }
                      >
                        {children}
                      </div>
                    )
                  }
                  }

                  style={{ height: "75vh" }}
                />
              }
              {expandCalendar &&
                <DragAndDropCalendar
                  localizer={localizer}
                  culture={culture}
                  toolbar={false}
                  events={events}
                  date={this.state.date.toDate()}

                  defaultView="month"
                  views={["month"]}
                  popup

                  selectable
                  onSelectSlot={this.onSelectSlot}
                  onSelectEvent={this.onSelectEvent}
                  onNavigate={this.onNavigate}
                  onEventDrop={this.moveEvent}

                  dayPropGetter={this.customDayPropGetter}
                  startAccessor={(event) => moment(event.date, "YYYY-MM-DD").toDate()}
                  endAccessor={(event) => moment(event.date, "YYYY-MM-DD").toDate()}
                  titleAccessor={this.titleAccessor}
                  eventPropGetter={this.eventPropGetter}

                  components={{
                    event: MyEvent,
                    month: {
                      dateHeader: this.DateCellWrapper
                    }
                  }}

                  style={{ height: (this.state.maxHeight + "vh") }}
                />
              }
            </Segment>

          </div>

          {this.state.showFormModal &&
            <FormModal action={action}
              type={type}
              currentCompany={this.state.currentCompany}
              editable={this.state.editable}
              values={this.state.values}
              selectedDates={this.state.selectedDates}
              handleChange={this.handleChange}
              handleReceipt={this.handleFile}
              handleSecondReceipt={this.handleSecondReceipt}

              onClose={this.closeModal}
              onSubmit={this.handleSubmit}
              onEdit={this.handleEdit}
              onDelete={this.handleDeleteEvent}

              loadings={this.props.loadings}
              options={this.props.options}

              expenseReceipt={this.state.expenseReceipt}
              expenseSecondReceipt={this.state.expenseSecondReceipt}

              cutoff={this.props.cutoff}
              amount_error={this.state.amount_error}

              fileUploaded={this.state.fileUploaded}
              secondFileUploaded={this.state.secondFileUploaded}

              companyDoubleReceipt={company.double_receipt}
            />
          }

          {this.state.showPreferredProjectsModal &&
            <PreferredProjectsModal
              onClose={this.closeModal}
              projects={this.props.projects}
              loading={this.props.loadings.project}
              setUserPreferredProject={this.setUserPreferredProject}
            />
          }

          {this.state.showReportsModal &&
            <ReportFormModal
              onClose={this.closeModal}
              downloadReport={this.getReport}
            />
          }

          {this.state.showClearModal &&
            <Modal size="tiny" dimmer='inverted' open={true} onClose={this.closeModal}>
              {type === types.HOURS ? (<Modal.Content>Do you wish to delete the selected activities?</Modal.Content>) :
                <Modal.Content>Do you wish to delete the selected expenses?</Modal.Content>}
              <Modal.Actions>
                <Button className='grey'
                  negative
                  onClick={this.closeModal}>No</Button>
                <Button onClick={this.handleDeleteEvents}>Yes</Button>
              </Modal.Actions>
            </Modal>
          }

        </Sidebar.Pusher>
      </Sidebar.Pushable>

    );
  }

}

const MyEvent = (props) => {
  return <div onContextMenu={(e) => {
    //console.log(e.button);
    e.preventDefault();
    return false;
  }}>
    {props.title}
  </div>;
};


const mapStateToProps = state => ({
  cutoff: state.cutoffReducer.cutoff,
  user: state.authReducer.user,
  cn: state.authReducer.cn,
  name: state.authReducer.name,
  user_id: state.authReducer.user_id,
  unique_id: state.authReducer.unique_id,
  company_details: state.authReducer.company_details,
  user_details: state.authReducer.user_details,
  loading_consultants: state.manageConsultantsReducer.loading,
  consultants: _.toArray(
    _.mapValues(state.manageConsultantsReducer.consultants, (o) => {
      return {
        key: o.cn_code,
        value: o.cn_code,
        text: o.surname + " " + o.name + " (" + o.cn_code + ")" + (o.active ? " - Active" : " - Inactive"),
        is_dummy: o.is_dummy,
        user_id: o.id,
        name: o.name + " " + o.surname,
        company_code: o.company_code
      };
    })
  ),


  loading_hours: state.trHoursReducer.loading,
  hours: state.trHoursReducer.hours,
  tr_holidays: state.holidayReducer.tr_holidays,
  loading_tr: state.holidayReducer.loading_tr,
  loading_expenses: state.trExpensesReducer.loading,
  expenses: state.trExpensesReducer.expenses,

  companies: state.companyReducer.companies,

  projects: state.trProjectsReducer.projects,

  options: {
    project: _.toArray(
      _.mapValues(state.trProjectsReducer.projects, (o) => {
        return {
          key: o.counter,
          value: `${o.id}$${o.code}$${o.name}$${o.color}$${o.tr_label}`,
          text: o.tr_label + ' (' + o.name + ')',
          icon: o.favourite ? (<Icon name='star' color='yellow' />) : undefined,
          sales_level_id: o.sales_level_id,
          project_id: o.id,
          budget_start_date: (o.budget_start_date === null) ? null : new Date(o.budget_start_date).setUTCHours(0, 0, 0, 0),
          budget_end_date: (o.budget_end_date === null) ? null : new Date(o.budget_end_date).setUTCHours(0, 0, 0, 0),
        };
      })
    ),
    hour_type: _.toArray(
      _.mapValues(state.trHoursReducer.types, (o) => {
        return {
          key: o.id,
          value: `${o.id}$${o.code}`,
          text: o.name + " (" + o.code + ")"
        };
      })
    ),
    expense_type: _.toArray(
      _.mapValues(state.trExpensesReducer.types, (o) => {
        return {
          key: o.id,
          value: `${o.id}$${o.code}$${o.allowance}$${o.auto_approve}$${o.need_receipt}$${o.unit}$${o.default_company_paid}$${o.double_receipt ? o.double_receipt : 0}`,
          text: o.name + " (" + o.code + ")"
        };
      })
    ),
    office_location: [
      {
        "key": "office",
        "value": "Office",
        "text": "Office"
      },
      {
        "key": "customer",
        "value": "Customer",
        "text": "Customer"
      },
      {
        "key": "home",
        "value": "Home",
        "text": "Home"
      },
      {
        "key": "eu-see-ch",
        "value": "EU-SEE-CH",
        "text": "EU-SEE-CH"
      }
    ]
  },

  loadings: {
    hour_type: state.trHoursReducer.loading_types,
    expense_type: state.trExpensesReducer.loading_types,
    project: state.trProjectsReducer.loading
  }
});

const mapDispatchToProps = dispatch => {
  return {
    getHours: (cn_code, params) => dispatch(startGetTrHours(cn_code, params, permission_code_read)),
    getExpenses: (cn_code, params) => dispatch(startGetTrExpenses(cn_code, params, permission_code_read)),
    getHolidays: (params, userID) => dispatch(startGetTRHolidays(params, userID, permission_code_read)),

    getUserProjects: (userID, is_dummy) => dispatch(startGetTrProjectList(userID, is_dummy, permission_code_write)),
    setUserPreferredProject: (userID, projectId, status, sales_level_id, is_dummy) => dispatch(startTogglePreferredProject(userID, projectId, status, sales_level_id, is_dummy, permission_code_write)),

    getUserHourTypes: (userID, is_dummy) => dispatch(startGetTrHourTypes(userID, is_dummy, permission_code_read)),
    getUserExpenseTypes: (userID, is_dummy) => dispatch(startGetTrExpenseTypes(userID, is_dummy, permission_code_read)),
    createTrHour: (cn_code, params, refetchParams) => dispatch(startCreateTrHour(cn_code, params, refetchParams, permission_code_write, permission_code_read)),
    editTrHour: (userID, hourId, params, refetchParams) => dispatch(startEditTrHour(userID, hourId, params, refetchParams, permission_code_write, permission_code_read)),
    deleteTrHour: (userID, is_dummy, hourId) => dispatch(startDeleteTrHour(userID, is_dummy, hourId, permission_code_write)),
    deleteTrHours: (userID, is_dummy, hours) => dispatch(startDeleteTrHours(userID, is_dummy, hours, permission_code_write)),

    createTrExpense: (userID, params, refetchParams, file, secondReceipt) => dispatch(startCreateTrExpense(userID, params, refetchParams, file, secondReceipt, permission_code_write, permission_code_read)),
    editTrExpense: (userID, expenseId, params, refetchParams, file, secondReceipt) => dispatch(startEditTrExpense(userID, expenseId, params, refetchParams, file, secondReceipt, permission_code_write, permission_code_read)),
    deleteTrExpense: (userID, is_dummy, expenseId, S3Key, secondReceiptS3Key) => dispatch(startDeleteTrExpense(userID, is_dummy, expenseId, S3Key, secondReceiptS3Key, permission_code_write)),

    getCutoff: (userID, is_dummy) => dispatch(startGetCutoff(userID, is_dummy, permission_code_read)),

    getActivitiesReport: (cn_code, params, name) => dispatch(startGetActivitiesReport(cn_code, params, name, permission_code_read)),
    getExpensesReport: (cn_code, params, user_id, currency, name) => dispatch(startGetExpensesReport(cn_code, params, user_id, currency, name, permission_code_read)),

    searchConsultant: (params) => dispatch(startSearchAllConsultant(params, permission_code_read)),
    getCompanies: () => dispatch(startGetCompanies()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeReportContainer);
