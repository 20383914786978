import { Input, Select } from "semantic-ui-react"
import strings from "../strings"

export default [
  [
    {
      name: "code",
      label: "Project Code",
      placeholder: "Insert the code",
      readOnly: true,
      control: Input,
    },
    {
      name: "name",
      label: "Project Description",
      placeholder: "Insert description",
      readOnly: false,
      control: Input,
    },
  ],
  [
    {
      name: "company_name",
      label: "Company Name",
      placeholder: "",
      readOnly: false,
      control: Input,
    },
    {
      name: "company_code",
      label: "Company Code",
      placeholder: "",
      readOnly: false,
      control: Input,
    },
  ],
  [
    {
      name: "type_name",
      label: "Project Type",
      placeholder: strings.select_placeholder,
      readOnly: true,
      control: Select,
    },
    {
      name: "invoice_type_name",
      label: "Invoice Type",
      placeholder: strings.select_placeholder,
      readOnly: true,
      control: Select,
    },
  ],
  [
    {
      name: "billing_customer_name",
      label: "Billing Customer",
      placeholder: strings.select_placeholder,
      readOnly: true,
      control: Select,
    },
    {
      name: "final_customer_name",
      label: "Final Customer",
      placeholder: strings.select_placeholder,
      readOnly: true,
      control: Select,
    },
  ],

  [
    {
      name: "manager_fullName",
      control: Select,
      label: "Project Manager",
      placeholder: strings.select_placeholder,
      readOnly: true,
    },
    {
      name: "customerManager_fullName",
      control: Select,
      label: "Customer Manager",
      placeholder: strings.select_placeholder,
      readOnly: true,
    },
  ],

  // AEP-137
  [
    {
      name: "approver_fullName",
      control: Select,
      label: "Hours / Expenses Approver",
      placeholder: strings.select_placeholder,
      readOnly: true,
    },
    // AEP-4332
    {
      name: "offering_type",
      control: Select,
      label: "Offering Type",
      placeholder: strings.select_placeholder,
      readOnly: true,
    },
    // AEP-4332
  ],
  // AEP-137
  {
    name: "currency_code",
    control: Select,
    label: "Currency",
    placeholder: strings.select_placeholder,
    readOnly: false,
  },
  {
    name: "status_name",
    label: "Status",
    readOnly: true,
    control: Input,
  },
]
