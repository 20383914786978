import React, { Component } from "react";
import { connect } from "react-redux";
import {
  startApproveExpense,
  startGetExpenseDetails,
  startGetExpenseReceipt, startGetVats,
  startRejectExpense, startSubmitVAT,
  startGetExpensePaymentReceipt
} from "../../../../store/actions/core/administration/receipts";
import TextAreaModal from "../../../../components/core/administration/receipts/TextAreaModal";
import ReceiptPreviewer from "../../../../components/core/administration/receipts/ReceiptPreviewer";
import { Button, Dimmer, Grid, Header, Icon, Item, Label, Loader, Modal, Table, Tab } from "semantic-ui-react";
import Can from "../../../../abilities/Can";
import _ from "lodash";
import SelectVATModal from "../../../../components/core/administration/receipts/SelectVATModal";

class ReceiptFromIdContainer extends Component {

  // Constructor
  constructor(props) {
    super(props);
    this.state = {
      expense_id: this.props.expenseId,
      isRejectModalOpen: false,
      isSelectVATModalOpen: false,
      activeIndex: 0
    };
  }



  // Lyfecicle Hook(s)
  componentDidMount() {
    this.props.getExpenseDetails(this.state.expense_id, {});
    this.props.getVats(this.props.companyId);
  }

  handleRejectButtonClick = (e) => {
    this.setState({ isRejectModalOpen: true });
  };

  handleRejectModalClose = () => {
    this.setState({ isRejectModalOpen: false });
  };

  handleSelectVATClick = () => {
    console.log("file: ReceiptFromIdContainer.js:45 ~ ReceiptFromIdContainer ~ getVats - companyId", this.props.companyId)
    this.props.getVats(this.props.companyId);
    this.setState({ isSelectVATModalOpen: true });
  };

  handleSelectVATModalClose = () => {
    this.setState({ isSelectVATModalOpen: false });
  }

  approveExpense = () => {
    this.props.approveExpense(this.state.expense_id);
  };

  rejectExpense = (reason) => {
    this.props.rejectExpense(this.state.expense_id, reason);
    this.props.onClose();
  };

  submitVAT = (vat) => {
    this.props.submitVAT(this.state.expense_id, vat);
    this.props.onClose();
  }

  getExpenseReceipt = () => {
    if (this.props.expenseDetails) {
      this.props.getExpenseReceipt(this.props.expenseDetails.receipt_key);
    }
  };

  getExpensePaymentReceipt = () => {
    if (this.props.expenseDetails) {
      this.props.getExpensePaymentReceipt(this.props.expenseDetails.second_receipt_key);
    }
  };

  onTabChange = (_event, data) => {
    this.setState({ activeIndex: data.activeIndex })
  }

  // To Render Component
  render() {
    const data = this.props.expenseDetails ? this.props.expenseDetails : [];
    //const approvable = this.props.approvable;
    const approved = this.props.approved;
    //const companyId = this.props.companyId;
    //const companyCode = this.props.companyCode;

    //console.log(data, approvable, companyId, companyCode);
    return (
      <Can I='administration_receipts:Read' a='all'>
        <Modal size="large"
          dimmer='inverted'
          open={true}
          onClose={this.props.onClose}
          closeIcon>
          <Modal.Header> Receipt Details {
            !this.props.loading &&
            !this.props.error &&
            <Label color={data.status_code === "OK" ? "green" : (data.status_code === "KO_PM" || data.status_code === "KO_HR") ? "red" : data.status_code === "PENDING" ? "orange" : data.status_code === "MR" ? "yellow" : "grey"} tag>
              {data.status_code === "OK" ? "Approved" : data.status_code === "KO_PM" ? "Rejected by PM" : data.status_code === "KO_HR" ? "Rejected by HR" : data.status_code === "PENDING" ? "Waiting for approval" : data.status_code === "MR" ? "Missing receipt" : "Sent to SAP"}
            </Label>
          }
          </Modal.Header>
          <Modal.Content scrolling={true}>
            <Dimmer active={this.props.loading} inverted>
              <Loader inverted>Loading</Loader>
            </Dimmer>
            <Dimmer active={this.props.error} inverted>
              <Header as='h3' style={{ color: "black" }}>
                Error
                <Header.Subheader>{this.props.error}</Header.Subheader>
              </Header>
            </Dimmer>
            <Grid>
              <Grid.Column width={16}>
                <Button
                  icon
                  labelPosition='left'
                  floated='right'
                  onClick={this.getExpensePaymentReceipt}
                  disabled={this.props.loading || this.props.error || !data.second_receipt_key}>
                  <Icon name="cloud download" />
                  Download Payment Receipt
                </Button>
                <Button
                  icon
                  labelPosition='left'
                  floated='right'
                  onClick={this.getExpenseReceipt}
                  disabled={this.props.loading || this.props.error || data === [] || !data.receipt_key}>
                  <Icon name="cloud download" />
                  Download Receipt
                </Button>
                <br />
                <Item.Group>
                  <Item>
                    <Item.Content>
                      <Item.Header>Project</Item.Header>
                      <Item.Meta>The details of the project</Item.Meta>
                      <Item.Description>
                        <Table definition>
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell width={3}>Name</Table.Cell>
                              <Table.Cell>{data.project_name}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={3}>Code</Table.Cell>
                              <Table.Cell>{data.project_code}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                  <br />
                  <Item>
                    <Item.Content>
                      <Item.Header>Receipt</Item.Header>
                      <Item.Meta>The details of the receipt</Item.Meta>
                      <Item.Description>
                        <Table definition>
                          <Table.Header />
                          <Table.Body>
                            <Table.Row>
                              <Table.Cell width={3}>Type</Table.Cell>
                              <Table.Cell>{data.type_code}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={3}>Date</Table.Cell>
                              <Table.Cell>{data.date}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={3}>Amount</Table.Cell>
                              <Table.Cell>{typeof data.amount === 'number' ? data.amount.toFixed(2) : data.amount}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={3}>Unit</Table.Cell>
                              <Table.Cell>{data.currency}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={3}>Paid By Company</Table.Cell>
                              <Table.Cell>{data.company_paid ? "Yes" : "No"}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={3}>VAT</Table.Cell>
                              <Table.Cell>{data.vat_description ? data.vat_description : ""}</Table.Cell>
                            </Table.Row>
                            <Table.Row>
                              <Table.Cell width={3}>Notes</Table.Cell>
                              <Table.Cell>{data.notes}</Table.Cell>
                            </Table.Row>
                          </Table.Body>
                        </Table>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                </Item.Group>
                <Tab
                  onTabChange={this.onTabChange}
                  panes={[
                    this.props.expenseReceipt && data.receipt_key && {
                      menuItem: {
                        content: 'Receipt',
                        className: this.state.activeIndex === 0 ? 'menu-item-active' : 'menu-item'
                      },
                      render: () => (
                        <ReceiptPreviewer
                          url={this.props.expenseReceipt}
                        />
                      )
                    },
                    this.props.expensePaymentReceipt && data.second_receipt_key && {
                      menuItem: {
                        content: 'Payment Receipt',
                        className: this.state.activeIndex === 1 ? 'menu-item-active' : 'menu-item'
                      },
                      render: () => (
                        <ReceiptPreviewer
                          url={this.props.expensePaymentReceipt}
                        />
                      )
                    },
                  ]
                  }
                />

              </Grid.Column>
            </Grid>

            <TextAreaModal
              open={this.state.isRejectModalOpen}
              onClose={this.handleRejectModalClose}
              onReject={this.rejectExpense}
            />
            <SelectVATModal
              open={this.state.isSelectVATModalOpen}
              onClose={this.handleSelectVATModalClose}
              onSubmit={this.submitVAT}
              loading_vats={this.props.loading_vats}
              vats={this.props.vats}
              vat_code={data.vat_code || ""}
            />

          </Modal.Content>
          <Modal.Actions>
            {

              // If in APPROVED status
              data !== [] && data.status_code === "OK" && approved === 0 &&

              <Can I="administration_receipts:Update" a='all-report'>
                <Button
                  negative
                  disabled={this.props.loading && this.props.error && data === []}
                  floated='right'
                  onClick={this.handleRejectButtonClick}>
                  Reject
                </Button>
              </Can>

            }
            {

              // If in REJECTED BY HR status facciamo riapprovare perchè magari hanno sbagliato a cliccare
              data !== [] && data.status_code === "KO_HR" && approved === 0 &&

              <Button
                disabled={this.props.loading && this.props.error && data === []}
                floated='right'
                onClick={this.approveExpense}
                loading={this.props.loadingApproval}>
                Approve
              </Button>

            }
            {console.log("stampa: ",/*data !== [] && data.status_code === "OK" &&*/ approved === 0)}
            {
              // If in APPROVED status e in germania hanno bisogno di selezionare l'IVA
              data !== [] && data.status_code === "OK" && approved === 0 &&
              <Can I="administration_receipts:Update" a='expenses_VAT'>
                <Button
                  disabled={this.props.loading && this.props.error && data === []}
                  floated='right'
                  onClick={this.handleSelectVATClick}>
                  {data.vat_code !== null ? "Edit VAT" : "Select VAT"}
                </Button>
              </Can>
            }
          </Modal.Actions>
        </Modal>
      </Can>
    );
  }

}

function mapStateToProps(state) {
  return {
    loading: state.receiptsReducer.loading,
    loadingApproval: state.receiptsReducer.loadingApproval,
    loadingReceipt: state.receiptsReducer.loadingReceipt,
    expenseDetails: state.receiptsReducer.expenseDetails,
    expenseReceipt: state.receiptsReducer.expenseReceipt,
    expensePaymentReceipt: state.receiptsReducer.expensePaymentReceipt,
    error: state.receiptsReducer.error,
    vats: _.toArray(
      _.mapValues(state.receiptsReducer.vats, o => {
        return {
          key: o.code,
          value: o.code,
          text: o.description,
          is_default: o.is_default
        };
      })
    ),
    loading_vats: state.receiptsReducer.loading_vats
  };
}

const mapDispatchToProps = dispatch => {
  return {
    getExpenseDetails: (id, params) => dispatch(startGetExpenseDetails(id, params)),
    getVats: (company_id) => dispatch(startGetVats(company_id)),
    approveExpense: (id) => dispatch(startApproveExpense(id)),
    rejectExpense: (id, reason) => dispatch(startRejectExpense(id, reason)),
    submitVAT: (id, vat) => dispatch(startSubmitVAT(id, vat)),
    getExpenseReceipt: (receipt_key) => dispatch(startGetExpenseReceipt(receipt_key)),
    getExpensePaymentReceipt: (receipt_key) => dispatch(startGetExpensePaymentReceipt(receipt_key)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReceiptFromIdContainer);
