import React, { Component } from "react";
import { Button, Checkbox, Form, Modal, Transition, Select } from "semantic-ui-react";
import { types } from "../../../../containers/core/administration/manage-types/ManageTypesContainer";
import HourTypeFormFields from "../../../../assets/resources/hour_type";
import ExpenseTypeFormFields from "../../../../assets/resources/expense_type";
import IconButton from "../../../shared/buttons/IconButton";
import Can from "../../../../abilities/Can";


class ManageTypesFormModal extends Component {

  state = {
    required: [],
    showDeleteButtons: false
  };

  componentWillUnmount() {
    this.setState({ showDeleteButtons: false });
  }

  componentDidMount() {
    const formFields = this.props.type === types.HOUR ? HourTypeFormFields : ExpenseTypeFormFields;
    const required = [];

    formFields.forEach((field, i) => {
      if (Array.isArray(field)) {
        field.forEach((groupField, ii) => {
          if (groupField.required) {
            required.push(groupField.name);
          }
        }
        );
      } else {
        if (field.required) {
          required.push(field.name);
        }
      }
    });
    this.setState({ required });
  }

  /**
   * Check if all required parameters have a value
   * @returns {boolean} true if at least one required value is empty, false otherwise
   */
  checkFormInvalidity = () => {
    for (let i = 0; i < this.state.required.length; i++) {
      if (!this.props.values[this.state.required[i]]) return true;
    }
    return false;
  };

  // checkUnit = (field) => {
  //   let val = this.props.values;
  //   if(field.name === "unit"){
  //     if(!val.hasOwnProperty("unit_check") || val.unit_check === 0 || !val.unit_check){
  //       val.unit = null;
  //       return true;
  //     }
  //   }
  //   return false;
  // }

  showDeleteButtons = () => {
    this.setState({ showDeleteButtons: true });
  };

  render() {

    const { values, loadings, options, type, editable } = this.props;
    var formFields = type === types.HOUR ? HourTypeFormFields : ExpenseTypeFormFields;

    return (
      <Modal size="small"
        dimmer='inverted'
        open={true}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onClose={this.props.onClose}
        closeIcon>
        <Modal.Header>
          {type === types.EXPENSE && this.props.active ? "Expense Type" :
            type === types.EXPENSE && !this.props.active ? "Expense Type - NOT ACTIVE" :
              type === types.HOUR && this.props.active ? "Hour Type" :
                type === types.HOUR && !this.props.active ? "Hour Type - NOT ACTIVE" : ""}
        </Modal.Header>
        <Modal.Content scrolling={true}>
          <Form>
            {!editable ?
              <div>
                {formFields.map((field, i) => {
                  if (Array.isArray(field)) {
                    return (
                      <Form.Group widths='equal' key={i}>
                        {field.map((groupField, ii) => {
                          if (values[groupField.name] !== "") {
                            if (groupField.name === 'double_receipt' && !values["need_receipt"]) {
                              return ""
                            }

                            return (
                              <div className='custom-form-field' key={ii}>
                                <label>{groupField.label}</label>
                                {groupField.control !== Checkbox ?
                                  <div>{values[groupField.name]}</div>
                                  : values[groupField.name] === 0 ?
                                    <div>No</div>
                                    : <div>Yes</div>}
                              </div>
                            );

                          } else {
                            return "";
                          }
                        })}
                      </Form.Group>
                    )
                  } else if (values[field.name] !== "") {

                    if (field.name === "unit_check" && values[field.name]) {
                      //console.log("dentro", values.unit);
                      return (
                        <div className='equal width fields' key='unit'>
                          <div className='custom-form-field' key={i}>
                            <label>{field.label}</label>
                            {field.control !== Checkbox ?
                              <div>{values[field.name]}</div>
                              : values[field.name] === 0 ?
                                <div>No</div>
                                : <div>Yes</div>}
                          </div>
                          <div className='custom-form-field' key={"unit"}>
                            <label>{"Unit of Measure"}</label>
                            <div>{values.unit}</div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className='custom-form-field' key={i}>
                          <label>{field.label}</label>
                          {field.control !== Checkbox ?
                            <div>{values[field.name]}</div>
                            : values[field.name] === 0 ?
                              <div>No</div>
                              : <div>Yes</div>}
                        </div>
                      );
                    }
                  } else {
                    return "";
                  }
                })}

              </div>

              : (
                <div>

                  {formFields.map((field, i) => {
                    if (Array.isArray(field)) {

                      return (
                        <Form.Group widths='equal' key={i}>
                          {
                            field.map((groupField, ii) => {

                              if (groupField.name === "double_receipt" && !values["need_receipt"])
                                return null;

                              if (groupField.control === Checkbox) {
                                groupField.checked = values[groupField.name];
                                groupField.readOnly = false;
                              } else {
                                groupField.value = values[groupField.name];
                              }

                              return (

                                <Form.Field
                                  key={ii}
                                  {...groupField}
                                  options={options[groupField.name]}
                                  loading={loadings[groupField.name]}
                                  onChange={this.props.handleChange}
                                />

                              );

                            }
                            )
                          }
                        </Form.Group>
                      );


                    } else {
                      if (field.control === Checkbox) {
                        field.checked = values[field.name];
                      } else if (field.name === "code") {
                        field.readOnly = (!this.props.editCode);
                        field.value = values[field.name];
                      } else {
                        field.value = values[field.name];
                      }

                      if (field.name === "unit_check" && values[field.name]) {
                        return (
                          <div key="unit" className="equal width fields">
                            <Form.Field
                              key={i}
                              {...field}
                              options={options[field.name]}
                              loading={loadings[field.name]}
                              onChange={this.props.handleChange}
                            />
                            <Form.Field
                              key={"unit"}
                              name={"unit"}
                              value={values["unit"]}
                              control={Select}
                              options={options["unit"]}
                              loading={loadings["unit"]}
                              label="Unit of Measure"
                              onChange={this.props.handleChange}
                            />
                          </div>
                        );
                      } else {
                        return (
                          <Form.Field
                            key={i}
                            {...field}
                            options={options[field.name]}
                            loading={loadings[field.name]}
                            onChange={this.props.handleChange}
                          />
                        );

                      }
                    }
                  })
                  }
                </div>

              )
            }

          </Form>
        </Modal.Content>
        <Modal.Actions>

          {!editable ?

            <div>
              <Can I="manage_types:Update" a='all'>
                {this.props.active ? <Button floated='left' negative onClick={this.showDeleteButtons}>Deactivate</Button> : ""}

                <Transition visible={this.state.showDeleteButtons} animation="fade right" duration={500}>
                  <span style={{ float: "left", padding: "0 10px" }}>
                    <span style={{ margin: "10px" }}>Are you sure?</span>
                    <Button className='grey'
                      negative
                      onClick={() => this.setState({ showDeleteButtons: false })}>No</Button>
                    <Button onClick={this.props.onDelete}>Yes</Button>
                  </span>
                </Transition>
                {this.props.active ? <Button floated='right' onClick={this.props.onEdit}>Edit</Button> : ""}
              </Can>
            </div>
            :
            <div>
              <Can I="manage_types:Create" a='all'>
                <IconButton
                  floated='right'
                  icon='plus'
                  label="Submit"
                  disabled={this.checkFormInvalidity()}
                  onClick={this.props.onSubmit}
                />
              </Can>
            </div>
          }
        </Modal.Actions>
      </Modal>
    );
  }
}

export default ManageTypesFormModal;
